import React from 'react';
import { Button, Header, Image } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import 'components/common/ImproperAuthentication.scss';

const ImproperAuthentication: React.FC = (_props) => {
    const history = useHistory();

    return (
        <>
            <Image size="medium" centered src="/RemedioUX-Branding-Black.svg" />
            <div className="bigDiv">
                <Header as="h2" textAlign="center">
                    Authentication Error
                </Header>
                <div className="ui-message">
                    <div className="header">
                        You do not have permission to view this page
                    </div>
                    <p>You can either go back or log in as a different user</p>
                </div>
                <br />
                <Button onClick={() => history.goBack()}>Go Back</Button>
                <Button color = "blue" className="loginButton" href="/login">Login as Different User</Button>
            </div>
        </>
    );
};

export default ImproperAuthentication;