import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import isNil from 'lodash/isNil';
import { DEFAULT_UI_THEME, UITheme } from 'external/form/DefaultFormComponents';
import { isBlank } from 'common/util/StringHelpers';
import Multifield from 'external/form/Multifield';
import { RuleProperties } from 'json-rules-engine';

const convertTypesToOptions = (types: Array<{ id: number, name: string }>) => {
    return types.map((type) => { return {id: type.id.toString(), text: type.name};});
};

export type GenericFormSchema = {
    fields: Record<string, GenericFormFieldSchema>,
    fieldOrder: string[]
}

export type GenericFormFieldSchema = {
    allowComment: true,
    type: string,
    component?: string,
    label: string,
    required: boolean,
    maxLength: number,
    disabled: boolean,
    options: Array<{id: string, text: string}>,
    helpText?: string,
    multiple: boolean,
    placeHolder: string,
    disallowFutureTimes: boolean,
    dateFormat: string,
    timeFormat: string,
    minDateTime: string,
    maxDateTime: string,
    rows: number,
    mask: string,
    disallowPastTimes: boolean,
    autoFocus?: boolean,
    allowMultipleFields: boolean,
    maxFields: number,
    loadData: undefined,
    requiredWhen?: RuleProperties,
}

export interface FormData {
    [fieldName: string]: FormDataValue;
}

export type FormDataValue = string | number | null | object | boolean | Array<string | number | null | object | boolean>;

// TODO: Checkbox layout, text patterns, select filtering

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExampleSchema: GenericFormSchema = { // eslint-disable-line no-unused-vars
    fields: {
        name: {
            // hidden | text | password | masktext | textarea | select
            // checkbox | datetime | fileupload | imageinput | subheading | plaintextarea | number
            // component | flexible | userpicker | link
            allowComment: true,
            type: 'text',
            component: undefined, // The component to use for the form display
            label: 'Name', // The label to display
            required: true, // Whether field is required
            maxLength: 30, // Maximum length of field
            disabled: true, // Whether field is disabled
            options: [{
                id: '1',
                text: 'Option 1',
            }, {
                id: '2',
                text: 'Option 2',
            }], // List of drop down options for a select list
            multiple: true, // Whether multiple be selected
            placeHolder: 'Some text to use as a placeholder',
            disallowFutureTimes: true, // For datetime fields, disallow times in the future
            dateFormat: 'DD-MM-YYYY', // date field format
            timeFormat: 'HH:mm', // time field format
            minDateTime: '2017-01-01T00:00:00+00:00', // iso formatted date-time to restrict min selectable date-time
            maxDateTime: '2018-01-01T00:00:00+00:00', // iso formatted date-time to restrict max selectable date-time
            rows: 3, // number of rows of plain textarea
            mask: 'mask', // see https://github.com/Gusto/react-masked-field
            disallowPastTimes: true, // For datetime fields, disallow times in the past
            autoFocus: true, // indicate field is to be default focus
            allowMultipleFields: false, // Whether multiple copies of the field are allowed
            maxFields: 0, // Maximum number of copies of the field, (0 = unlimited)
            loadData: undefined, // Callback to load record data in select field
            // schema: { }, // Schema object used for flexible fields
        },
    },
    fieldOrder: ['name', 'description', '...'], // The ordering of the fields
};

const FieldError = ({name, type}: {name: string, type: string}) => {
    return (
        <div className="text-danger">
            Warning! Field not defined: <b>{name} - {type}</b>
        </div>
    );
};

type ValidationFunction = (
    fieldValue: string,
    fieldConfiguration: any,
    formData: any,
) => {error: boolean, errorMessage: string}

type Props = {
    children: React.ReactNode,
    schema: GenericFormSchema,
    formData: Record<string, any>,
    formSaved: boolean,
    onFormDataChanged: (newFormData: any) => void,
    onInputFieldChanged: (name: string, value: string) => void,
    onSubmit: (formData: any) => Promise<any>,
    onCancel: () => void,
    onSubmitSuccess: (data: any) => void,
    onSubmitError: (error: Error) => void,
    showButtons: boolean,
    submitButtonLabel: string,
    submitButtonDisabled: boolean,
    styleClass: string,
    formClass: string,
    customValidators: Record<string, ValidationFunction>,
    theme: UITheme,
    allowedFields: Record<string, React.ComponentType>,
    registerValidateFunctionCallback: (validateFunctionCallback: () => void) => {},
    fieldError: typeof FieldError,
}

type State = {
    validators: Record<string, ValidationFunction>,
    formData: Record<string, any>,
    errors: Record<any, any>,
    fieldReferences: Record<string, any>,
    formEdited: boolean,
}

class GenericForm extends React.Component<Props, State> {
    public static defaultProps = {
        children: undefined,
        formData: {},
        formSaved: false,
        onFormDataChanged: () => {},
        onSubmit: () => {},
        onCancel: undefined,
        onSubmitSuccess: () => {},
        onSubmitError: undefined,
        showButtons: true,
        submitButtonLabel: 'Save', // ActionType.Save,
        submitButtonDisabled: false,
        onInputFieldChanged: undefined,
        styleClass: '',
        formClass: 'form-horizontal col-sm-12 col-md-10 col-lg-10',
        customValidators: {},
        theme: DEFAULT_UI_THEME,
        allowedFields: {},
        registerValidateFunctionCallback: () => {},
        fieldError: FieldError,
    };

    /**
     * Prevent the form auto submitting if it receives an Enter key press
     */
    static onFormKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter' && (event.target as Element).localName !== 'textarea') {
            event.preventDefault();
        }
    }

    constructor(props: Props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            formData: this.props.formData,
            errors: {},
            fieldReferences: {},
            formEdited: false,
            validators: {},
        };
    }

    componentDidMount() {
        this.props.registerValidateFunctionCallback(() => this.validate(this.props.customValidators));
    }

    static getDerivedStateFromProps(nextProps: Props) {
        return {
            formData: nextProps.formData,
        };
    }

    registerValidator(name: 'string', validationFunction: ValidationFunction) {
        this.setState(prevState => ({
            validators: {
                ...prevState.validators,
                [name]: validationFunction
            }
        }));
    }

    deregisterValidator(name: string) {
        this.setState((prevState: State) => {
            const validators = {...prevState.validators,};
            delete validators[name];
            return {validators};
        });
    }

    onInputChanged(name: string, value: string) {
        const newFormData = Object.assign({}, this.state.formData);
        newFormData[name] = value;

        this.setState({
            formData: newFormData,
            formEdited: true,
        });

        if (this.props.onFormDataChanged) {
            this.props.onFormDataChanged(newFormData);
        }
        if (this.props.onInputFieldChanged) {
            this.props.onInputFieldChanged(name, value);
        }
    }

    getFormData() {
        return this.state.formData;
    }

    getMultiField(fieldControl: any, field: any) {
        return (
            <Multifield
                key={field.name}
                allowMultipleFields={field.allowMultipleFields}
                maxFields={field.maxFields}
                fieldControl={fieldControl}
                onChange={this.onInputChanged}
                error={this.state.errors[field.name]}
                {...field}
                theme={this.props.theme}
                registerValidator={this.registerValidator}
                deregisterValidator={this.deregisterValidator}
            />
        );
    }

    getFormField(fieldData: {name: string, type: string}) {
        const fieldControl = this.props.allowedFields[fieldData.type];
        if (isNil(fieldControl)) {
            const FieldError = this.props.fieldError;
            return <FieldError {...fieldData} />;
        }
        return this.getMultiField(fieldControl, fieldData);
    }

    /**
     * @param customValidators: Map of field names to validator functions.
     * Form fields matching the custom validator key will have the validator function run against the form value.
     *
     * Validator functions should return a validation outcome object as defined below:
     *   (fieldValue, fieldConfiguration, formData) => {error: bool, errorMessage: string}
     * With the following details:
     *   fieldValue: The value that the field has
     *   fieldConfiguration: The options supplied with the field
     *   formData: The options supplied with the field
     *   error: Whether there was a field validation error
     *   errorMessage: User prompt if the value is invalid.
     */
    validate(customValidators: Record<string, ValidationFunction>) {
        const fieldErrors = {};
        Object.keys(this.state.validators).forEach((fieldKey: string) => {
            const validationFunction = this.state.validators[fieldKey];
            const fieldValue = this.state.formData[fieldKey];
            const fieldConfiguration = this.props.schema.fields[fieldKey];
            this.executeValidationFunction(fieldKey, fieldValue, fieldConfiguration, fieldErrors, validationFunction);
        })


        if (!isNil(customValidators)) {
            this.executeCustomValidators(customValidators, fieldErrors)
        }

        this.setState({
            errors: fieldErrors,
        });

        return Object.keys(fieldErrors).length === 0;
    }

    executeCustomValidators(customValidators: Record<string, ValidationFunction>, fieldErrors: Record<string, string>) {
        Object.keys(customValidators).forEach(fieldKey => {
            const validationFunction = customValidators[fieldKey];
            const fieldValue = this.state.formData[fieldKey];
            const fieldConfiguration = this.props.schema.fields[fieldKey];
            this.executeValidationFunction(fieldKey, fieldValue, fieldConfiguration, fieldErrors, validationFunction);
        });
    }

    executeValidationFunction(fieldKey: string, fieldValue: string, fieldConfiguration: any, fieldErrors: Record<string, string>, validationFunction: ValidationFunction) {
        const {error, errorMessage} = validationFunction(fieldValue, fieldConfiguration, this.state.formData);
        if (error) {
            fieldErrors[fieldKey] = errorMessage;
        }
    }

    handleSubmitError(error: Error) {
        if (this.props.onSubmitError) {
            this.props.onSubmitError(error);
        } else {
            // ErrorHandler(error);
        }
    }

    submit(event: React.SyntheticEvent) {
        event.preventDefault();
        if (this.validate(this.props.customValidators)) {
            if (this.props.onSubmit) {
                this.setState({formEdited: false}, () =>
                    this.props.onSubmit(this.state.formData)
                        .then((data) => {
                            if (this.props.onSubmitSuccess) {
                                this.props.onSubmitSuccess(data);
                            }
                        })
                        .catch(this.handleSubmitError),
                );
            }
        }
    }

    cancel(event: React.SyntheticEvent) {
        event.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const formFields = this.props.schema.fieldOrder.map((fieldName) => {
            const fieldDetail = Object.assign({
                name: fieldName,
                value: isNil(this.state.formData[fieldName]) ? undefined : this.state.formData[fieldName],
            }, this.props.schema.fields[fieldName]);
            return this.getFormField(fieldDetail);
        });

        let formButtons;
        const Button = this.props.theme.formButton;
        const ButtonContainer = this.props.theme.formButtonListContainer;
        if (this.props.showButtons) {
            formButtons = (
                <ButtonContainer>
                    <Button onClick={this.cancel}>
                        Cancel
                    </Button>
                    <Button disabled={this.props.submitButtonDisabled} primary onClick={this.submit}>
                        {this.props.submitButtonLabel}
                    </Button>
                </ButtonContainer>
            );
        }

        const Form = this.props.theme.form;
        return (
            <div
                onKeyPress={GenericForm.onFormKeyDown}
            >
                <Form>
                    {formFields}
                    {this.props.children}
                    {formButtons}
                </Form>
            </div>
        );
    }
}

const isEmptyFieldValidator = (field: string) => {
    return (formData: any) => {
        if (formData[field]) {
            if (isBlank(formData[field].trim())) {
                return false;
            }
        }
        return true;
    };
};

export {
    GenericForm as default,
    convertTypesToOptions,
    isEmptyFieldValidator,
};
