import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import Markdown from 'common/Markdown';
import FieldInformation from 'common/form/fields/FieldInformation';
import FieldFeeSchedule from 'common/form/fields/FieldFeeSchedule';
import FieldRedressSchedule from 'common/form/fields/FieldRedressSchedule';
import StyledAccordion from 'components/common/StyledAccordion';
import FieldCheckList from 'common/form/fields/FieldCheckList';
import { FieldCheckBox } from 'external/form/GenericFormFields';
import FieldToggle from 'common/form/fields/FieldToggle';
import FieldAdvisorTagSearch from 'common/form/fields/FieldAdvisorTagSearch';

function getDisplayValueFromSelectField(item) {
    if (!item.options) {
        return item.value;
    }

    const option = item.options.find(option => option.id === item.value);

    if (!option || !option.text) {
        return item.value;
    }

    return option.text;
}

function getDisplayValue(item) {
    if (item.markup) {
        return <Markdown text={item.value} />;
    }

    if (item.fieldType === 'information') {
        return <FieldInformation name={item.name} value={item.value} disabled />;
    }

    if (item.fieldType === 'feeschedule') {
        return <FieldFeeSchedule name={item.name} value={item.value} disabled />;
    }

    if (item.fieldType === 'redressschedule') {
        return <FieldRedressSchedule name={item.name} value={item.value} options={item.options} disabled />;
    }

    if (item.fieldType === 'checklist') {
        return <FieldCheckList name={item.name} value={item.value} options={item.options} readonly />;
    }

    if (item.fieldType === 'checkbox') {
        return <FieldCheckBox name={item.name} value={item.value} options={item.options} />;
    }

    if (item.fieldType === 'toggle') {
        return <FieldToggle name={item.name} value={item.value} options={item.options} />;
    }

    if (item.fieldType === 'select') {
        return <>{getDisplayValueFromSelectField(item)}</>
    }

    if (item.fieldType === 'advisortag') {
        return <FieldAdvisorTagSearch name={item.name} value={item.value} options={item.options} readonly />;
    }

    return (
        <>
            {item.value}
        </>
    );

}

const ReferenceData = ({data}) => {
    if (data.length === 0) {
        return null;
    }

    return (
        <StyledAccordion title="Key Review Data">
            <List divided>
                {data.map(item =>
                    <List.Item key={item.fieldId}>
                        <List.Header>{item.description}</List.Header>
                        {getDisplayValue(item)}
                    </List.Item>,
                )}
            </List>
        </StyledAccordion>
    );
};

ReferenceData.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape(),
    ),
    expanded: PropTypes.bool,
};

ReferenceData.defaultProps = {
    data: [],
    expanded: false,
};

export default ReferenceData;
