import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Loader, Segment } from "semantic-ui-react";
import BasicApi from 'api/BasicApi';
import CaseDataAccordion from 'components/case/CaseDataAccordion';
import ReviewStageFixedHeader from 'components/case/reviews/ReviewStageFixedHeader';

class CaseFieldData extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            loading: false,
            screens: [],
        };
        this.ref = createRef();
    }

    componentDidMount() {
        this.getCaseFieldScreens();
    }

    getCaseFieldScreens() {
        this.setState({loading: true});
        BasicApi.get(`/api/cases/${this.props.caseId}/screens`)
            .then(this.setCaseFieldScreens)
            .catch(BasicApi.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCaseFieldScreens(data) {
        this.setState({
            screens: data,
        });
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        return (
            <div ref={this.ref}>
                <ReviewStageFixedHeader reviewId={this.props.taskId} contextRef={this.ref} showHeader={false} />
                <Segment>
                    <CaseDataAccordion screens={this.state.screens} caseId={this.props.caseId} />
                </Segment>
            </div>
        );
    }
}

CaseFieldData.propTypes = {
    caseId: PropTypes.string.isRequired,
    taskId: PropTypes.string,
};

CaseFieldData.defaultProps = {};

export default CaseFieldData;