import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import BasicApi from 'api/BasicApi';
import { Button, Dropdown, Header, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class AllocateTaskModal extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            users: [],
            loading: false,
            selectedUser: null,
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        this.setState({loading: true})
        BasicApi.get('/api/users')
            .then(this.setUsers)
            .finally(() => this.setState({loading: false}))
    }

    setUsers(data) {
        const usersAsDropdownOptions = data.map(user => {return {key: user.id, value: user.id, text: user.name}})
        this.setState({users: usersAsDropdownOptions})
    }

    onClose() {
        this.setState({selectedUser: null})
        this.setState({selectedUser: null})
        this.props.onClose();
    }

    onSubmit() {
        this.props.onSubmit(this.props.taskId, this.state.selectedUser);
        this.onClose();
    }

    render() {
        return (
            <Modal
                open={this.props.visible}
                onClose={this.onClose}
            >
                <Header>
                    Task Allocation
                </Header>
                <Modal.Content>
                    <p>
                        Who would you like to allocate this task to?
                    </p>
                    <Dropdown
                        fluid
                        search
                        selection
                        clearable
                        placeholder='Select a user'
                        options={this.state.users}
                        onChange={(event, data) => this.setState({selectedUser: data.value})}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.onClose}
                        content='Cancel'
                    />
                    <Button
                        primary
                        onClick={this.onSubmit}
                        content='Submit'
                        disabled={!this.state.selectedUser}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

AllocateTaskModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    taskId: PropTypes.string,
    visible: PropTypes.bool,
}

AllocateTaskModal.defaultProps = {
    taskId: undefined,
    visible: false,
}

export default AllocateTaskModal