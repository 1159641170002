import React, { useEffect } from 'react';
import FieldTagSearch from 'common/form/fields/FieldTagSearch';
import { Input, Label } from 'semantic-ui-react';
import {
    ERROR_FIELD_REQUIRED,
    executeDefaultValidators,
    getErrorResponse,
} from 'external/form/Validators';
import { isNil } from 'lodash';

function runDefaultValidators(fieldData, fieldConfiguration, formData) {
    if (fieldConfiguration.required
        && (isNil(fieldData)
            || (fieldData.length === 0)
            || (fieldData.selectedTags.length === 0))
    ) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    return executeDefaultValidators(fieldData, fieldConfiguration, formData);
}

const FieldAdvisorTagSearch = (props) => {
    useEffect(() => {
        if (props.registerValidator && props.deregisterValidator) {
            props.registerValidator(props.name, runDefaultValidators);
            return function cleanup() {
                props.deregisterValidator(props.name);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.name, props.registerValidator, props.deregisterValidator]);

    const MAX_RESULTS = 5;
    const searchPath = `/api/advisors?size=${MAX_RESULTS}&query=`;

    const mapToTag = (tag) => {
        return {
            key: tag.advisorId,
            id: tag.advisorId,
            name: tag.afsLicenseName,
            title: `${tag.afsLicenseName} [${tag.afsLicenseNumber}]`,
        };
    };

    if (props.readonly) {
        return (
            <>
                <ul>
                    {props.value?.selectedTags?.map(tag => (
                        <li key={tag.key}>
                            {tag.name}
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    if (props.disabled) {
        return (props.value?.selectedTags?.map(tag => (
            <Label key={tag.key}>
                {tag.name}
            </Label>
        ))) || <Input disabled />;
    }

    return (
        <FieldTagSearch
            {...props}
            mapToTag={mapToTag}
            searchPath={searchPath}
            idField={"advisorId"}
        />
    );
};

export default FieldAdvisorTagSearch;
