import React from 'react';
import PropTypes from 'prop-types';
import { convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from 'draft-js';
import bindClassMethods from 'common/util/AutoBind';
import 'draft-js/dist/Draft.css';
import { isBlank } from 'common/util/StringHelpers';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Button, Dropdown, Header, Icon, Popup, Segment } from 'semantic-ui-react';

const styleMap = {
    MONOSPACE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    STRIKETHROUGH: {
        textDecoration: 'line-through',
    },
};

const HEADING_TYPES = [
    {id: 'unstyled', text: 'Paragraph', class: 'span'},
    {id: 'header-one', text: 'Heading 1', class: 'h1'},
    {id: 'header-two', text: 'Heading 2', class: 'h2'},
    {id: 'header-three', text: 'Heading 3', class: 'h3'},
    {id: 'header-four', text: 'Heading 4', class: 'h4'},
    {id: 'header-five', text: 'Heading 5', class: 'h5'},
    {id: 'header-six', text: 'Heading 6', class: 'h6'},
];

const LIST_TYPES = [
    {label: 'Unordered List', style: 'unordered-list-item', icon: 'list ul'},
    {label: 'Ordered List', style: 'ordered-list-item', icon: 'list ol'},
];

const BLOCK_TYPES = [
    {label: 'Block Quote', style: 'blockquote', icon: 'quote left'},
];

const INLINE_TYPES = [
    {label: 'Bold', style: 'BOLD', icon: 'bold'},
    {label: 'Italic', style: 'ITALIC', icon: 'italic'},
    // Not supported yet in Markdown transform
    // {label: 'Underline', style: 'UNDERLINE', icon: 'underline'},
    // {label: 'Strikethrough', style: 'STRIKETHROUGH', icon: 'strikethrough'},
    // {label: 'Monospace', style: 'MONOSPACE', icon: 'text width'},
];

class StyledTextArea extends React.Component {

    static getBlockStyle(block) {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return null;
        }
    }

    static getMarkdownFromEditorState(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        return draftToMarkdown(rawContentState);
    }

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            editorState: this.createEditorState(),
            showToolbar: false,
        };
        this.editor = React.createRef();
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            const editorState = EditorState.moveFocusToEnd(this.state.editorState);
            this.onChange(editorState);
            this.showToolbar();
        }
    }

    createEditorState() {
        let editorState;
        if (isBlank(this.props.value)) {
            editorState = EditorState.createEmpty();
        } else {
            const rawData = markdownToDraft(this.props.value);
            const contentState = convertFromRaw(rawData);
            editorState = EditorState.createWithContent(contentState);
        }
        return editorState;
    }

    focusEditor() {
        this.editor.current.focus();
    }

    onChange(editorState, callback) {
        if (this.props.disabled) {
            return;
        }
        if (this.props.onChange) {
            if (editorState.getCurrentContent().hasText()) {
                this.props.onChange(StyledTextArea.getMarkdownFromEditorState(editorState));
            } else {
                this.props.onChange('');
            }
        }
        this.setState({editorState}, callback);
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType), () => this.focusEditor());
    }

    toggleInlineStyle(inlineStyle) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle), () => this.focusEditor());
    }

    showToolbar() {
        this.setState({showToolbar: true});
    }

    render() {
        const {editorState, showToolbar} = this.state;
        return (
            <>
                {showToolbar && !this.props.disabled && (
                    <EditorToolbar
                        editorState={editorState}
                        onToggleBlock={this.toggleBlockType}
                        onToggleInline={this.toggleInlineStyle}
                    />
                )}
                <Segment attached disabled={this.props.disabled}>
                    <div onClick={this.focusEditor}>
                        <Editor
                            {...this.props}
                            blockStyleFn={StyledTextArea.getBlockStyle}
                            customStyleMap={styleMap}
                            editorState={editorState}
                            handleKeyCommand={this.handleKeyCommand}
                            onChange={this.onChange}
                            onFocus={this.showToolbar}
                            placeholder={this.props.placeHolder}
                            readOnly={this.props.disabled}
                            ref={this.editor}
                            spellCheck
                        />
                    </div>
                </Segment>
            </>
        );
    }
}

StyledTextArea.propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
};

StyledTextArea.defaultProps = {
    autoFocus: false,
    disabled: false,
    onChange: undefined,
    placeHolder: '',
    value: '',
};

const StyleButton = (props) => {
    const {active, onToggle, label, iconClass} = props;

    const handleToggle = (e) => {
        e.preventDefault();
        onToggle();
    };

    return (
        <Popup
            content={label}
            mouseEnterDelay={400}
            mouseLeaveDelay={50}
            on='hover'
            trigger={
                <Button onMouseDown={handleToggle} active={active}>
                    <Icon name={iconClass} />
                </Button>
            }
        />
    );
};

StyleButton.propTypes = {
    active: PropTypes.bool.isRequired,
    iconClass: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    onToggle: PropTypes.func.isRequired,
};

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    const {buttonTypes} = props;

    return (
        <Button.Group>
            {buttonTypes.map(type => (
                <StyleButton
                    active={currentStyle.has(type.style)}
                    iconClass={type.icon}
                    key={type.label}
                    label={type.label}
                    onToggle={() => props.onToggle(type.style)}
                />
            ))}
        </Button.Group>
    );
};

InlineStyleControls.propTypes = {
    buttonTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    editorState: PropTypes.shape().isRequired,
    onToggle: PropTypes.func.isRequired,
};

const BlockStyleControls = (props) => {
    const {onToggle, blockType, buttonTypes} = props;
    return (
        <Button.Group>
            {buttonTypes.map(type => (
                <StyleButton
                    active={type.style === blockType}
                    iconClass={type.icon}
                    key={type.label}
                    label={type.label}
                    onToggle={() => onToggle(type.style)}
                />
            ))}
        </Button.Group>
    );
};

BlockStyleControls.propTypes = {
    blockType: PropTypes.string,
    buttonTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onToggle: PropTypes.func.isRequired,
};

BlockStyleControls.defaultProps = {
    blockType: undefined,
};

const StyledOption = (props) => {
    const {data} = props;
    return (
        <span className={data.class}>{data.text}</span>
    );
};

StyledOption.propTypes = {
    data: PropTypes.shape().isRequired,
};

const HeadingStyleDropdown = (props) => {
    const {blockType, onSelect} = props;

    const onChange = (e, data) => {
        onSelect(data.value);
    };
    const style = HEADING_TYPES.find(item => item.id === blockType) || {text: 'Unstyled'};
    return (
        <Dropdown button text={style.text}>
            <Dropdown.Menu>
                {HEADING_TYPES.map((option) => (
                    <Dropdown.Item
                        key={option.id}
                        onClick={onChange}
                        value={option.id}
                        active={option.id === blockType}
                    >
                        <Header as={option.class}>{option.text}</Header>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

HeadingStyleDropdown.propTypes = {
    onSelect: PropTypes.func.isRequired,
    blockType: PropTypes.string,
};

HeadingStyleDropdown.defaultProps = {
    blockType: HEADING_TYPES[0].id,
};

const EditorToolbar = (props) => {
    const {editorState, onToggleInline, onToggleBlock} = props;

    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <Segment attached="top" secondary style={{padding: '3px'}}>
            <HeadingStyleDropdown onSelect={onToggleBlock} blockType={blockType} />
            <InlineStyleControls editorState={editorState} onToggle={onToggleInline} buttonTypes={INLINE_TYPES} />
            &nbsp;
            <BlockStyleControls onToggle={onToggleBlock} blockType={blockType} buttonTypes={LIST_TYPES} />
            &nbsp;
            <BlockStyleControls onToggle={onToggleBlock} blockType={blockType} buttonTypes={BLOCK_TYPES} />
        </Segment>
    );
};

EditorToolbar.propTypes = {
    editorState: PropTypes.shape().isRequired,
    onToggleBlock: PropTypes.func.isRequired,
    onToggleInline: PropTypes.func.isRequired,
};

export {
    StyledTextArea as default,
};
