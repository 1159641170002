import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Container } from 'semantic-ui-react';
import UploadFilesModal from 'components/common/UploadFilesModal';
import StagedFilesList from 'components/customer/StagedFilesList';
import { createCustomerFile } from 'common/util/FileUploadHelpers';
import BasicApi from 'api/BasicApi';
import ConfirmDelete from 'components/common/ConfirmDelete';
import AlertMessage, { AlertLevel } from 'components/common/AlertMessage';

class CustomerStagedFilesList extends Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            files: [],
            loading: false,
            apiError: null,
            uploadFileModalVisible: false,
            deletingFile: null,
            _isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({_isMounted: true});
        this.getStagedFiles();
    }

    componentWillUnmount() {
        this.setState({_isMounted: false});
    }

    handleApiError(error) {
        this.setState({apiError: error.message});
    }

    getStagedFiles() {
        const customerId = this.props.customerId;
        this.setState({loadingFiles: true});
        BasicApi.get(`/api/customers/${customerId}/staged-files`)
            .then(this.setFiles)
            .catch(this.handleApiError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({loadingFiles: false});
                }
            });
    }

    setFiles(data) {
        if (this.state._isMounted) {
            this.setState({files: data});
        }
    }

    onUploadFiles(files) {
        return new Promise((resolve, reject) => {
            if (files) {
                const uploadPromiseArray = files.map((file) => createCustomerFile(this.props.customerId, file))
                return Promise.all(uploadPromiseArray)
                    .then(res => {
                        this.getStagedFiles();
                        return resolve(res)
                    })
                    .catch(err => reject(err));
            }
            return reject("Please Select a file.");
        })
    }

    deleteSelectedFile() {
        const customerId = this.props.customerId;
        BasicApi.delete(`/api/customers/${customerId}/staged-files/${this.state.deletingFile.stagedFileId}`)
            .then(this.getStagedFiles)
            .catch(this.handleApiError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({deletingFile: null})
                }
            });
    }

    render() {
        return (
            <>
                {this.state.apiError &&
                 <AlertMessage
                     level={AlertLevel.Error}
                     heading={"API Error"}
                     content={this.state.apiError}
                     dismissible={true}
                 />
                }
                {!this.props.readOnly &&
                 <>
                     {<ConfirmDelete
                         open={this.state.deletingFile !== null}
                         onConfirm={this.deleteSelectedFile}
                         onCancel={() => this.setState({deletingFile: null})}
                         title="Delete File?"
                         content={"Are you sure you want to delete this file?"}
                     />
                     }
                     <UploadFilesModal
                         visible={this.state.uploadFileModalVisible}
                         onClose={() => this.setState({uploadFileModalVisible: false})}
                         onSubmit={this.onUploadFiles}
                     />
                     <Container textAlign="right" fluid>
                         <Button
                             compact
                             primary
                             onClick={() => this.setState({uploadFileModalVisible: true})}
                             icon='add'
                             content='Add Artefacts'
                         />
                     </Container>
                 </>
                }
                <StagedFilesList
                    onFileSelect={this.props.onFileSelect}
                    onFileDelete={this.props.readOnly ? undefined : (stagedFile) => this.setState({deletingFile: stagedFile})}
                    downloadUrl={`/api/customers/${this.props.customerId}/files`}
                    files={this.state.files}
                />
            </>
        )
    }
}

CustomerStagedFilesList.propTypes = {
    customerId: PropTypes.string.isRequired,
    onFileSelect: PropTypes.func,
    readOnly: PropTypes.bool,
}

CustomerStagedFilesList.defaultProps = {
    onFileSelect: undefined,
    readOnly: false,
}

export default CustomerStagedFilesList