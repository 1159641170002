import React from 'react';
import 'App.css';
import Theme from 'Theme';
import StickyLayout from 'common/StickyLayout';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { UserContextProvider } from 'UserContext';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Logout from 'common/auth/Logout';
import RequireAuthentication from 'common/auth/RequireAuthentication';
import Help from 'help/Help';
import ErrorBoundary from 'common/util/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import ConfigurationDataContext from 'components/common/ConfigurationContext';
import ProgrammeRouter from 'components/programme/ProgrammeRouter';
import SearchPage from 'components/search/SearchPage';
import LoginPage from 'common/auth/LoginPage';
import PasswordManagement from "common/auth/PasswordChangeModal";
import AppAdminRouter from 'components/admin/AppAdminRouter';
import ImproperAuthenticationPage from 'components/common/ImproperAuthentication';

library.add(faHandHoldingUsd);

function App() {
    return (
        <ErrorBoundary>
            <Router>
                <UserContextProvider>
                    <ConfigurationDataContext>
                        <Theme>
                            <Switch>
                                <Route path="/login" exact>
                                    <LoginPage />
                                </Route>
                                <StickyLayout
                                    header={<Header />}
                                    footer={<Footer />}
                                    body={(
                                        <Switch>

                                            <Route path="/help/triage" exact>
                                                <Help />
                                            </Route>

                                            <Route path="*" exact>
                                                <RequireAuthentication>
                                                        <Route path="/" exact>
                                                            <Redirect to="/programmes" />
                                                        </Route>

                                                        <Route path="/logout" exact>
                                                            <Logout />
                                                        </Route>

                                                        <Route path="/user/password" exact>
                                                            <PasswordManagement />
                                                        </Route>

                                                        <Route path="/programmes" component={ProgrammeRouter} />

                                                        <Route path="/admin" component={AppAdminRouter} />

                                                        <Route path="/unauthenticated" component={ImproperAuthenticationPage} />

                                                        <Route path="/search">
                                                            <SearchPage />
                                                        </Route>
                                                </RequireAuthentication>
                                            </Route>
                                        </Switch>
                                    )}
                                />
                            </Switch>
                        </Theme>
                    </ConfigurationDataContext>
                </UserContextProvider>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
