import React from 'react';
import isNil from 'lodash/isNil';
import './defaultForm.css';

const HIDDEN_FIELD_TYPE = 'hidden';

type Props = {
    required?: boolean,
    error?: string,
    label: string,
    propertyName: string,
    type?: string,
}

const DefaultFormField: React.FunctionComponent<Props> = ({required = false, ...props}) => {
    let style = undefined;
    if (props.type && props.type.toLowerCase() === HIDDEN_FIELD_TYPE) {
        style = {
            display: 'none',
        };
    }
    return (
        <div style={style}>
            <label htmlFor={props.propertyName}>
                {props.label}{required ? <span className="default-form-required-field">*</span> : ''}
            </label>
            <div>
                {props.children}
                {!isNil(props.error) && <div className="default-form-error-message">{props.error}</div>}
            </div>
        </div>
    );
};

const DefaultForm: React.FunctionComponent = ({children}) => {
    return (
        <form>
            {children}
        </form>
    );
};

type DefaultButtonProps = {
    label: string,
    onClick: () => void,
}

const DefaultButton: React.FunctionComponent<DefaultButtonProps> = ({children, onClick}) => {
    return (
        <button onClick={onClick}>
            {children}
        </button>
    );
};

const DefaultButtonContainer: React.FunctionComponent = ({children}) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export type UITheme = {
    form: React.ComponentType<any>,
    formField: React.ComponentType<any>,
    formButton: React.ComponentType<any>,
    formButtonListContainer: React.ComponentType<any>,
}

const DEFAULT_UI_THEME: UITheme = {
    form: DefaultForm,
    formField: DefaultFormField,
    formButton: DefaultButton,
    formButtonListContainer: DefaultButtonContainer,
};

export {
    DefaultForm as Form,
    DefaultFormField as FormField,
    DefaultButton as FormButton,
    DEFAULT_UI_THEME,
};