import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Loader, Message, Segment } from 'semantic-ui-react';
import PageHeader from 'components/common/PageHeader';
import GenericForm from 'common/form/SemanticUIForm';
import { withRouter } from 'react-router-dom';
import BasicApi from 'api/BasicApi';

class NewUserForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: this.getFormSchema(),
            formData: {},
            loading: false,
            errorMessageList: [],
        };
    }

    componentDidMount() {
        this.getFields();
    }

    getFields() {
        this.setState({loading: true});
        BasicApi.get(`/api/groups`)
            .then(this.setGroups)
            .finally(() => this.setState({loading: false}));
    }

    setGroups(data) {
        const fieldOptions = data.map(group => {
            return {
                id: group.id,
                text: group.name,
            };
        });

        this.setState({
            schema: this.getFormSchema(fieldOptions),
        });
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Users', content: 'Users & User Groups'},
            {key: 'New User', content: 'New User'},
        ];
    }

    getFormSchema(groups = []) {
        return {
            fields: {
                username: {
                    type: 'text',
                    label: 'Username',
                    placeHolder: '',
                    required: true,
                },
                displayName: {
                    type: 'text',
                    label: 'Display Name',
                    required: true,
                    placeholder: '',
                },
                password: {
                    type: 'password',
                    label: 'Password',
                    required: true,
                },
                groupIds: {
                    type: 'multiselect',
                    label: 'Groups',
                    required: true,
                    placeHolder: '',
                    options: groups,
                },
            },
            fieldOrder: [
                'username',
                'displayName',
                'password',
                'groupIds',
            ],
        };
    }

    onFormDataChanged(data) {
        this.setState({formData: data});
    }

    onSubmit(formData) {
        this.setState({
            saving: true,
            error: '',
        });
        return BasicApi
            .post(`/api/users`, formData);
    }

    onCancel() {
        this.props.redirect();
    }

    onSubmitSuccess() {
        this.setState({saving: false});
        this.props.redirect();
    }

    onError(error) {
        this.setState({
            errorMessageList: error.messageList ?? [],
            saving: false
        });
    }

    render() {
        if (this.state.loading || this.state.loadingConfig) {
            return <Loader active />;
        }
        return (
            <>
                <PageHeader icon="user" text="New User" breadcrumbs={this.getBreadcrumbs()} />
                <Segment attached={"bottom"}>
                    {this.state.errorMessageList.length !== 0 &&
                        <Message negative>
                            <Message.Header>Could not create user</Message.Header>
                            <ul>{
                                this.state.errorMessageList.map(msg => {
                                    return <li>{msg}</li>
                                })
                            }</ul>
                        </Message>
                    }
                    <GenericForm
                        schema={this.state.schema}
                        onFormDataChanged={this.onFormDataChanged}
                        formData={this.state.formData}
                        navigationPrompt={false}
                        showButtons={true}
                        styleClass="left-align-fields"
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        onSubmitSuccess={this.onSubmitSuccess}
                        onSubmitError={this.onError}
                        submitButtonDisabled={this.state.saving}
                    />
                </Segment>
            </>
        );
    }
}

NewUserForm.propTypes = {
    redirect: PropTypes.func.isRequired,
};

NewUserForm.defaultProps = {};

export default withRouter(NewUserForm);