import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';
import { FieldSelect } from 'external/form/GenericFormFields';
import BasicApi from 'api/BasicApi';

const CustomerSelector = (props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const setCustomers = (data) => {
        const options = data
            .filter((customer) => {
                return !props.excludedCustomerIds.includes(customer.customerId)
            }).map((customer) => {
            return {
                key: customer.customerId,
                id: customer.customerId,
                text: customer.customerName,
            }
        });
        setCustomerOptions(options);
    }

    const onCustomerChanged = (customerId) => {
        const customer = customerOptions.find(option => option.id === customerId);
        setSelectedCustomer(customer);
    }

    const getCustomerOptions = () => {
        setLoading(true);
        BasicApi.get(`/api/newcase/customers`)
            .then(setCustomers)
            .catch(error => setError(error.message ?? "Error loading customers"))
            .finally(() => setLoading(false))
    }

    return (
        <Modal
            open={props.open}
            onMount={() => getCustomerOptions()}
            size='small'
            closeOnEscape={true}
            centered={false}
        >
            <Header icon='user' content='Add Customer To Case' />
            <Modal.Content>
                {error &&
                 <Message negative>
                     <Message.Header>There was an error</Message.Header>
                     <p>{error}</p>
                 </Message>
                }
                <Form loading={loading} autoComplete="off">
                    <Form.Field>
                        <label>Select Customer</label>
                        <FieldSelect
                            name='Select Customer'
                            onChange={(name, value) => onCustomerChanged(value)}
                            on
                            options={customerOptions}
                            placeHolder={'Select a customer'}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.onCancel}>
                    <Icon name='remove' /> Cancel
                </Button>
                <Button
                    disabled={selectedCustomer === null}
                    color='green'
                    onClick={() => props.onCustomerSelected(selectedCustomer)}
                >
                    <Icon name='checkmark' /> Add Customer
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

CustomerSelector.props = {
    open: PropTypes.bool.isRequired,
    onCustomerSelected: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    excludedCustomerIds: PropTypes.arrayOf(PropTypes.string),
}

CustomerSelector.defaultProps = {
    excludedCustomerIds: [],
}

export default CustomerSelector;