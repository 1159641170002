import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Checkbox, Container, Divider, Header, Icon, Input, Message } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import { Link } from 'react-router-dom';
import UploadFilesModal from 'components/common/UploadFilesModal';
import ConfirmModalButton from 'common/ConfirmModal';
import PageHeader from 'components/common/PageHeader';
import { importCustomersFromFile } from 'common/util/FileUploadHelpers';

class ImportExportScreen extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            backingUp: false,
            restoring: false,
            showImportModal: false,
            showImportWorkflowModal: false,
            showImportCustomerModal: false,
            importSuccess: false,
            importError: null,
            includeArtefacts: false,
            includeEmptyResponses: false,
            tag: '',
        };
    }

    getDataExportLink(format) {
        return `/api/etl/export/${format}`;
    }

    getDataImportLink() {
        return '/api/etl/import';
    }

    getDataImportWorkflowLink() {
        return '/api/workflow/import';
    }

    onImportData(files) {
        if (files.length > 0) {
            const formData = new FormData();
            BasicApi.addMultipartFile(formData, 'data', files[0])
            this.setState({restoring: true});
            return BasicApi.postMultiPartForm(this.getDataImportLink(), formData)
                .then(this.onImportSuccess)
                .catch(this.onImportFailure)
                .finally(() => this.setState({restoring: false}))
        }
        return Promise.reject("Please Select a file.");
    }

    onImportWorkflowData(files) {
        if (files.length > 0) {
            const formData = new FormData();
            BasicApi.addMultipartFile(formData, 'data', files[0])
            this.setState({restoring: true});
            return BasicApi.postMultiPartForm(this.getDataImportWorkflowLink(), formData)
                .then(this.onImportSuccess)
                .catch(this.onImportFailure)
                .finally(() => this.setState({restoring: false}))
        }
        return Promise.reject("Please Select a file.");
    }

    onImportCustomers(files) {
        return new Promise((resolve, reject) => {
            if (files) {
                const uploadPromiseArray = files.map((file) => importCustomersFromFile(file, this.state.tag))
                return Promise.all(uploadPromiseArray)
                    .then(res => {
                        return resolve(res)
                    })
                    .catch(err => reject(err));
            }
            return reject("Please Select a file.");
        }).catch(this.onImportFailure);
    }

    onImportSuccess() {
        this.setState({
            importSuccess: true,
            importError: null,
        })
    }

    onImportFailure(error) {
        this.setState({
            importSuccess: false,
            importError: error.message || error,
        })
    }

    showImportModal() {
        this.setState({showImportModal: true});
    }

    hideImportModal() {
        this.setState({showImportModal: false});
    }

    showImportWorkflowModal() {
        this.setState({showImportWorkflowModal: true});
    }

    hideImportWorkflowModal() {
        this.setState({showImportWorkflowModal: false});
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Import and Export', content: 'Import and Export'},
        ];
    }

    showImportCustomerModal() {
        this.setState({showImportCustomerModal: true});
    }

    hideImportCustomerModal() {
        this.setState({showImportCustomerModal: false});
    }

    onTagChange(event, data) {
        this.setState({
            tag: data.value,
        });
    }

    clickIncludeArtefacts() {
        this.setState({includeArtefacts: !this.state.includeArtefacts})
    }

    clickIncludeEmpty() {
        this.setState({includeEmptyResponses: !this.state.includeEmptyResponses})
    }

    getCaseExportAllLink() {
        const includeArtefacts = this.state.includeArtefacts ? "1" : "0";
        const includeEmpty = this.state.includeEmptyResponses ? "1" : "0";
        return `/api/etl/cases/export-all?includeArtefacts=${includeArtefacts}&includeEmpty=${includeEmpty}`;
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader text="Import and Export" icon="exchange" breadcrumbs={this.getBreadcrumbs()} />

                {this.state.importSuccess &&
                    <Message positive>
                        <Message.Header>The data was imported successfully</Message.Header>
                    </Message>
                }

                {this.state.importError &&
                    <Message negative>
                        <Message.Header>The data import failed</Message.Header>
                        <p>{this.state.importError}</p>
                    </Message>
                }

                <Divider hidden />

                <Container textAlign="left">
                    <Header as="h3">
                        <Icon name="cloud download" />
                        <Header.Content>
                            Export data
                            <Header.Subheader>Use the following to export customers and cases from this instance</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Button.Group>
                        <Button
                            disabled={this.state.backingUp}
                            loading={this.state.backingUp}
                            as={Link}
                            to={this.getDataExportLink('xlsx')}
                            download
                            target="_blank"
                        >
                            Export as XLSX
                        </Button>
                        <Button.Or />
                        <Button
                            disabled={this.state.backingUp}
                            loading={this.state.backingUp}
                            as={Link}
                            to={this.getDataExportLink('json')}
                            download
                            target="_blank"
                        >
                            Export as JSON
                        </Button>
                    </Button.Group>

                    <Divider />

                    <Header as="h3">
                        <Icon name="cloud upload" />
                        <Header.Content>
                            Import Data
                            <Header.Subheader>Use the following to import case data and customers (JSON)</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <ConfirmModalButton
                        onConfirm={this.showImportModal}
                        title="All duplicate records will be overwritten"
                        buttonText="Import"
                        message={
                            <>
                                <p>Any records already in the database will be overwritten.</p>
                                <p>Are you sure you wish to continue the import process?</p>
                            </>
                        }
                    />
                    <UploadFilesModal
                        onSubmit={this.onImportData}
                        visible={this.state.showImportModal}
                        onClose={this.hideImportModal}
                        allowMultiple={false}
                    />

                    <Divider />

                    <Header as="h3">
                        <Icon name="cloud upload" />
                        <Header.Content>
                            Import Workflow
                            <Header.Subheader>Use the following to import a new workflow (YAML)</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Button onClick={this.showImportWorkflowModal}>Import workflow</Button>
                    <UploadFilesModal
                        onSubmit={this.onImportWorkflowData}
                        visible={this.state.showImportWorkflowModal}
                        onClose={this.hideImportWorkflowModal}
                        allowMultiple={false}
                    />

                    <Divider />

                    <Header as="h3">
                        <Icon name="users" />
                        <Header.Content>
                            Population Import
                            <Header.Subheader>Use the following to import new customers from a tabular file (CSV)</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Button
                        onClick={this.showImportCustomerModal}
                        content="Import Customers"
                    />
                    <Link
                        to={'/api/customers/import'}
                        target="_blank"
                    ><Icon name="download" /> Download CSV Template</Link>
                    <UploadFilesModal
                        visible={this.state.showImportCustomerModal}
                        onClose={this.hideImportCustomerModal}
                        onSubmit={this.onImportCustomers}
                        allowMultiple={false}
                        header="Upload Customer CSV file"
                        submitButtonLabel="Import Customers"
                        submitButtonDisabled={this.state.tag === ''}
                    >
                        <Container textAlign="center">
                            <Divider horizontal><Icon name="arrow down" /></Divider>
                            <Input
                                icon="tag"
                                iconPosition="left"
                                placeholder="Add customer tag"
                                onChange={this.onTagChange}
                            />
                        </Container>
                    </UploadFilesModal>

                    <Divider />

                    <Header as="h3">
                        <Icon name="briefcase" />
                        <Header.Content>
                            Export Cases
                            <Header.Subheader>Use the following to export all cases as an archive</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <div>
                        <Checkbox
                            name="includeArtefacts"
                            onClick={this.clickIncludeArtefacts}
                            checked={this.state.includeArtefacts}
                        /> Include Supporting Documents
                    </div>
                    <div>
                        <Checkbox
                            name="includeEmptyResponses"
                            onClick={this.clickIncludeEmpty}
                            checked={this.state.includeEmptyResponses}
                        /> Include Empty Responses
                    </div>
                    <Button
                        as={Link}
                        to={this.getCaseExportAllLink()}
                        target="_blank"
                    ><Icon name="download" /> Export all cases</Button>

                </Container>
            </React.Fragment>
        );
    }
}

ImportExportScreen.propTypes = {
    children: PropTypes.node,
};

ImportExportScreen.defaultProps = {
    children: undefined,
};

export default ImportExportScreen;
