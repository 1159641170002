import { isBefore, isFutureDateTime, isPastDateTime, isValidDate, isValidDateTime } from 'common/util/DateHelpers';
import { isEmpty, isNil } from 'lodash';

const ERROR_FIELD_REQUIRED = 'This is a required field';
const ERROR_MAX_CHARACTERS_REACHED = 'Maximum characters is';
const ERROR_DATE_IN_THE_FUTURE = 'Date cannot be in the future';
const ERROR_MAX_VALUE = 'The maximum value is';
const ERROR_MIN_VALUE = 'The minimum value is';
const ERROR_DATE_IN_THE_PAST = 'Date cannot be in the past';
const ERROR_INVALID_DATE_TIME = 'Invalid date format';
const ERROR_INVALID_ZONED_DATE_MISSING_DATE_TIME = 'Timezone specified without date time';
const ERROR_INVALID_ZONED_DATE_MISSING_TZ = 'Date time specified without timezone';
const MINIMUM_DATE = '1754-01-01T00:00:00+00:00';

const NoValidationError = {
    error: false,
    errorMessage: undefined,
};

function getErrorResponse(errorMessage) {
    return {
        error: true,
        errorMessage: errorMessage,
    };
}

function isZoneDateTimeDefined(value) {
    return !isNil(value) && !isNil(value.date) && !isNil(value.tz);
}

function isFieldRequiredAndEmpty(value, field) {
    if (field.type === 'datetimezone') {
        const isUndefined = !isZoneDateTimeDefined(value);
        return field.required && isUndefined;
    }
    return (
        field.required && (isNil(value) || (value.length === 0))
    );
}

function isFieldTooLong(value, field) {
    if (!isNil(field.maxLength)
        && !isNil(value)
        && (value > field.maxLength)
    ) {
        return getErrorResponse(`${ERROR_MAX_CHARACTERS_REACHED} ${field.maxLength}`);
    }
    return NoValidationError;
}

function isNumberFieldMaximum(value, field) {
    if (field.type === 'number'
        && !isNil(field.maximum)
        && !isNil(value)
        && (value > field.maximum)
    ) {
        return getErrorResponse(`${ERROR_MAX_VALUE} ${field.maximum}`);
    }
    return NoValidationError;
}

function isNumberFieldMinimum(value, field) {
    if (field.type === 'number'
        && !isNil(field.minimum)
        && !isNil(value)
        && (value < field.minimum)
    ) {
        return getErrorResponse(`${ERROR_MIN_VALUE} ${field.minimum}`);
    }
    return NoValidationError;
}

function isDateFieldInvalid(value, field) {

    if (field.type === 'datetime') {
        return (!isNil(value) && (isBefore(value, MINIMUM_DATE) || !isValidDateTime(value)));
    }

    if (field.type === 'date') {
        return (!isNil(value) && (isBefore(value, MINIMUM_DATE) || !isValidDate(value)));
    }

}

function isZonedDateFieldInvalid(value, field) {
    if (field.type === 'datetimezone' && !isNil(value)) {
        if (isNil(value.date)) {
            return getErrorResponse(ERROR_INVALID_ZONED_DATE_MISSING_DATE_TIME);
        }
        if (isNil(value.tz)) {
            return getErrorResponse(ERROR_INVALID_ZONED_DATE_MISSING_TZ);
        }
        if (isBefore(value.date, MINIMUM_DATE) || !isValidDateTime(value.date)) {
            return getErrorResponse(ERROR_INVALID_DATE_TIME);
        }
    }
    return NoValidationError;
}

function isPastDateFieldInTheFuture(value, field) {
    return (
        field.type === 'datetime'
        && field.disallowFutureTimes
        && isFutureDateTime(value)
    );
}

function isFutureDateFieldInThePast(value, field) {
    return (
        field.type === 'datetime'
        && field.disallowPastTimes
        && isPastDateTime(value)
    );
}

function isCheckboxChecked(value, field) {
    if (field.type === 'checkbox'
        && value === false
    ) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    return NoValidationError;
}

function validatorErrorResponse(validatorFunction, errorMessage) {
    return (valueToValidate, fieldConfiguration) => {
        if (validatorFunction(valueToValidate, fieldConfiguration)) {
            return getErrorResponse(errorMessage);
        }

        return NoValidationError;
    };
}

/**
 * Takes a string and returns checks if the string is nil (null or undefined) or an empty string
 * @param data - data to validate as a string
 * @returns boolean
 */
const isNilOrEmpty = (data) => {
    if (!data) {
        return true
    }
    const dataStripped = data.trim();
    return isNil(dataStripped) || isEmpty(dataStripped);
}

const DefaultValidators = [
    validatorErrorResponse(isFieldRequiredAndEmpty, ERROR_FIELD_REQUIRED),
    isFieldTooLong,
    isNumberFieldMaximum,
    isNumberFieldMinimum,
    validatorErrorResponse(isDateFieldInvalid, ERROR_INVALID_DATE_TIME),
    isZonedDateFieldInvalid,
    validatorErrorResponse(isPastDateFieldInTheFuture, ERROR_DATE_IN_THE_FUTURE),
    validatorErrorResponse(isFutureDateFieldInThePast, ERROR_DATE_IN_THE_PAST),
    isCheckboxChecked,
];

function executeDefaultValidators(fieldData, fieldConfiguration, formData) {
    for (const validationFunction of DefaultValidators) {
        const result = validationFunction(fieldData, fieldConfiguration, formData);
        if (result.error) {
            return result;
        }
    }
    return NoValidationError;
}

export {
    NoValidationError,
    getErrorResponse,
    DefaultValidators,
    executeDefaultValidators,
    ERROR_FIELD_REQUIRED,
    isFieldRequiredAndEmpty,
    isFieldTooLong,
    isNumberFieldMaximum,
    isNumberFieldMinimum,
    isDateFieldInvalid,
    isZonedDateFieldInvalid,
    isPastDateFieldInTheFuture,
    isFutureDateFieldInThePast,
    isNilOrEmpty,
};