import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import {Button, Container, Label, List} from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import UnderConstructionWarning from 'components/common/UnderConstructionWarning';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';

class FieldControl extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            details: {},
            loading: true,
        };
    }

    componentDidMount() {
        this.loadFieldDetails();
    }

    loadFieldDetails() {
        this.setState({loading: true});
        BasicApi.get(`/noauth/field-permissions/${this.props.fieldId}`)
            .then(this.setDetails)
            .finally(() => this.setState({loading: false}));
    }

    setDetails(data) {
        this.setState({
            details: data,
        });
    }

    render() {
        if (this.state.loading) {
            return "Loading";
        }
        return (
            <>
                <UnderConstructionWarning />
                <PageHeader
                    text={`Field Permissions for ${this.state.details.displayName}`}
                    icon={<LoaderIcon icon="lock" onClick={this.loadSummary} loading={this.state.loading} />}
                />
                <Container textAlign="left">
                    Default permission: <Label>{this.state.details.defaultPermission}</Label>
                    <List divided selection>
                        <List.Header>Permissions</List.Header>
                        {this.state.details.permissions.map(permission => (
                            <List.Item>
                                <Label horizontal>
                                    {permission.permission}
                                </Label>
                                {permission.principalId}
                            </List.Item>
                        ))}
                        {(this.state.details.permissions.length === 0) &&
                            <List.Item>No permissions have been added yet.</List.Item>
                        }
                    </List>
                    <Button>Add</Button>
                </Container>
            </>
        );
    }
}

FieldControl.propTypes = {
    fieldId: PropTypes.string.isRequired,
};

FieldControl.defaultProps = {
};

export default FieldControl;