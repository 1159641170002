import React from 'react';
import bindClassMethods from 'common/util/AutoBind';

type Props = {
    allowMultiple: boolean,
    autoFocus: boolean,
    onFileInputChange: (files: File | File[]) => void,
}

type State = {

}

class FileInput extends React.Component<Props, State> {
    private inputField: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        bindClassMethods(this);
    }

    componentDidMount() {
        if (this.props.autoFocus === true) {
            this.inputField?.focus();
        }
    }

    handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        let file = null;
        if (event.target.files) {
            if (!this.props.allowMultiple) {
                file = event.target.files['0'];
            } else {
                file = Array.from(event.target.files);
            }
        }

        if (this.props.onFileInputChange) {
            this.props.onFileInputChange(file as (File | File[]));
        }
    }

    render() {
        return (
            <input
                type="file"
                id="file-upload"
                name="file-upload"
                onChange={this.handleFileInputChange}
                ref={(inputField) => {this.inputField = inputField;}}
                multiple={this.props.allowMultiple}
                data-cy="upload-file"
            />
        );
    }

}

export default FileInput;