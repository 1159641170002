import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { Container, Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import { Link, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { UserContextData, withUserContextProp } from 'UserContext';
import ShowWithPermission, { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority, {
    AdminScreenPermissions,
    AdminWorkflowPermissions,
    userHasAllPermissions,
    userHasAnyPermission,
} from 'common/auth/Authority';
import HeaderSearch from 'components/search/HeaderSearch';
import { ConfigurationData, withConfiguration } from 'components/common/ConfigurationContext';
import {
    getProgrammeIdFromCurrentPath,
    getProgrammeRoute,
} from 'components/programme/ProgrammeRouter';


const SHOW_HOME_LOGO_MENU = false;

type Props = {
    configurationData: ConfigurationData;
    userContext: UserContextData
} & RouteComponentProps;

type State = {
}

class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        bindClassMethods(this);
        this.state = {};
    }

    displaySearchBox() {
        return this.props.configurationData.allowCrossProgrammeSearch && getProgrammeIdFromCurrentPath();
    }

    render() {
        return (
            <Container fluid>
                {SHOW_HOME_LOGO_MENU &&
                    <Menu.Item as={Link} to="/" header style={{backgroundColor: '#367FA9'}}>
                        <Image size="mini" src="/logo.png" style={{marginRight: '1.5em'}} />
                        RECWalls
                    </Menu.Item>
                }

                {!this.props.userContext.isLoggedIn() &&
                    <Menu.Item position="right" as={Link} to="/login">
                        <Icon name="user outline" /> Login
                    </Menu.Item>
                }

                {this.props.userContext.isLoggedIn() &&
                    <>
                        {this.props.location.pathname !== "/programmes" && (
                            <Menu.Item as={Link} to="/programmes">
                                <Icon name="sitemap" /> Programs
                            </Menu.Item>
                        )}

                        <Route
                            path="/programmes/:programmeId" render={({match: {params: {programmeId}}}) => {
                            return (
                                <>
                                    <ShowWithOneOfPermission permissions={[Authority.ViewAllCases, Authority.ViewAssignedCases]}>
                                        <Menu.Item as={Link} to={`${getProgrammeRoute(programmeId)}/cases`}>
                                            <Icon name="briefcase" /> My Cases
                                        </Menu.Item>
                                    </ShowWithOneOfPermission>
                                    <ShowWithOneOfPermission
                                        permissions={[Authority.AddCustomer, Authority.EditCase,
                                            Authority.ViewAllCustomers]}
                                    >
                                        <Menu.Item as={Link} to={`${getProgrammeRoute(programmeId)}/customers`}>
                                            <Icon name="users" /> Customers
                                        </Menu.Item>
                                    </ShowWithOneOfPermission>

                                    <ShowWithOneOfPermission permissions={[Authority.CreateCase]}>
                                        <Menu.Item
                                            as={Link}
                                            to={{
                                                pathname: `${getProgrammeRoute(programmeId)}`,
                                                state: {createCase: true},
                                            }}
                                        >
                                            <Icon name="plus" /> Create Case
                                        </Menu.Item>
                                    </ShowWithOneOfPermission>

                                    <ShowWithPermission permission={Authority.Reporting}>
                                        <Dropdown.Item as={Link} to={`${getProgrammeRoute(programmeId)}/reports`}>
                                            <Icon name="chart line" /> Reports
                                        </Dropdown.Item>
                                    </ShowWithPermission>

                                    <ShowWithOneOfPermission permissions={AdminWorkflowPermissions} >
                                        <Dropdown.Item as={Link} to={`${getProgrammeRoute(programmeId)}/admin`}>
                                            <Icon name="cogs" /> Program Administration
                                        </Dropdown.Item>
                                    </ShowWithOneOfPermission>
                                </>
                            );
                        }}
                        >
                        </Route>

                        <Menu.Menu position="right">
                            {userHasAllPermissions([Authority.ViewAllCases, Authority.ViewAllCustomers], this.props.userContext)
                                && this.displaySearchBox()
                                && (
                                    <Menu.Item style={{padding: '5px'}}>
                                        <HeaderSearch />
                                    </Menu.Item>
                                )}
                            <Menu.Item>
                                <Dropdown trigger={<><Icon className="user" /> {this.props.userContext.user?.username}</>}>
                                    <Dropdown.Menu>
                                        {userHasAnyPermission(AdminScreenPermissions, this.props.userContext) && (
                                            <Dropdown.Item as={Link} to="/admin">
                                                <Icon name="cogs" />Administration
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item as={Link} to="/user/password">
                                            Change password
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/logout">
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        </Menu.Menu>
                    </>
                }

            </Container>
        );
    }
}

export default withConfiguration(withRouter(withUserContextProp(Header)));
