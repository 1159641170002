import React from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { withUserContextProp } from 'UserContext';
import bindClassMethods from 'common/util/AutoBind';
import { withRouter } from 'react-router-dom';
import BasicApi, { LOGIN_PATH } from 'api/BasicApi'

class Logout extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
    }

    logout(event) {
        BasicApi.logout()
            .then(v => {
                this.props.userContext.logout();
                this.props.history.push(LOGIN_PATH);
            })
    }

    render() {
        setTimeout(this.logout, 700);
        return (
            <Container textAlign="center">
                <Loader active inline>Logging out...</Loader>
            </Container>
        );
    }
}

// LoginForm.propTypes = {
//     children: PropTypes.node,
// };
//
// LoginForm.defaultProps = {
//     children: undefined,
// };

export default withRouter(withUserContextProp(Logout));