import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { RulesSemForm as RulesGenericForm} from 'common/form/SemanticUIForm';
import { Button, Container, Divider, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import BasicApi from 'api/BasicApi';
import OverrideStageComponent from 'components/case/reviews/OverrideStageComponent';
import StagingRecordLinks from 'components/case/StagingRecordLinks';
import { SavedStatus } from 'components/common/SavedStateLabel';
import ReferenceData from 'components/case/reviews/ReferenceData';
import NextStageDisplay from 'components/case/reviews/NextStageDisplay';
import 'components/case/reviews/CaseReviewStage.scss';
import { withUserContextProp } from 'UserContext';
import { getCaseListRoute } from 'components/case/CaseRouter';
import FieldRichText from 'common/form/fields/FieldRichText';
import Authority, { userHasAnyPermission } from 'common/auth/Authority';
import PauseButton from 'components/case/reviews/PauseButton';
import TaskProcessState from 'components/case/reviews/TaskProcessState';
import ReviewStageFixedHeader from 'components/case/reviews/ReviewStageFixedHeader';
import { AlertLevel, AlertMessageList } from 'components/common/AlertMessage';
import InformationModal from 'common/form/InformationModal';
import Markdown from 'common/Markdown';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';
import { AllocateToMeButton } from 'components/case/reviews/AllocateToMeButton';
import ShowWithPermission from 'common/auth/ShowWithPermission';

class CaseReviewStage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: undefined,
            screenData: undefined,
            formData: {},
            loadingData: true,
            savedStatus: SavedStatus.Init,
            saving: false,
            submitError: undefined,
            linkedStagedFiles: [],
            linkedStagedFilesUnsaved: [],
            linkedStagedRecords: [],
            linkedStagedRecordsUnsaved: [],
            redirectToDashboard: false,
            referenceData: [],
            showRejectModal: false,
            rejectComment: '',
            processState: null,
            onHoldReason: null,
            annotation: null,
        };

        this.contextRef = createRef();
    }

    componentDidMount() {
        this.loadScreenData(this.props.taskId);
    }

    loadScreenData(taskId) {
        this.setState({loadingData: true});
        BasicApi.get(`/api/reviews/${taskId}`)
            .then(this.setScreenData)
            .catch(BasicApi.logError)
            .finally(() => this.setState({loadingData: false}));
    }

    setScreenData(data) {
        this.setState({
            screenData: data,
            schema: data.schema,
            formData: data.fieldData,
            linkedStagedFiles: this.formatFiles(data.linkedStagedFiles),
            // linkedStagedRecords: this.formatRecords(data.linkedStagedRecords),   // Linked Records not implemented
            // in the backend yet.
            allowAddStagedFile: data.allowAddStagedFile,
            bypassStages: data.bypassStages,
            referenceData: data.referenceData,
            caseId: data.caseId,
            customerName: data.customerName,
            guidanceText: data.guidanceText,
            holdReasons: data.holdReasons,
            processState: data.processState,
            onHoldReason: data.onHoldReason,
            annotation: data.annotation,
        });
    }

    formatFiles(fileArray) {
        return fileArray.map(item => {
            return {
                id: item.stagedFileId,
                storageFileId: item.storageFileId,
                title: item.name,
                previewDetails: item.previewDetails,
            };
        });
    }

    formatRecords(recordArray) {
        return recordArray.map(item => {
            return {
                id: item.stagedRecordId,
                title: `${item.type} - ${item.description}`,
            };
        });
    }

    isReviewStageNotStarted() {
        return ((this.state.processState === TaskProcessState.NotStarted)
            || (this.state.processState === TaskProcessState.OnHold));
    }

    onFormDataChanged(formData) {
        this.setState({
            savedStatus: SavedStatus.Unsaved,
            formData: formData,
        });

        if (this.isReviewStageNotStarted()) {
            this.startTask();
        }
    }

    startTask() {
        this.setState({saving: true});
        BasicApi.put(`/api/reviews/${this.props.taskId}/start`, {})
            .then(() => {
                this.setState({
                    processState: TaskProcessState.InProgress,
                    onHoldReason: null,
                });
            })
            .catch(this.onSubmitError)
            .finally(() => this.setState({saving: false}));

    }

    getReviewData() {
        return {
            fieldData: this.state.formData,
            linkedStagedFiles: this.getLinkedStagedFileIds(),
            linkedStagedRecords: this.getLinkedStagedRecordIds(),
        };
    }

    getLinkedStagedFileIds() {
        return [...new Set(this.state.linkedStagedFiles.concat(this.state.linkedStagedFilesUnsaved)
            .map((file) => file.id))];
    }

    getLinkedStagedRecordIds() {
        return [...new Set(this.state.linkedStagedRecords.concat(this.state.linkedStagedRecordsUnsaved)
            .map((record) => record.id))];
    }

    getOverrideReviewData(overrideStageId, overrideComment) {
        return {
            fieldData: this.state.formData,
            linkedStagedFiles: this.getLinkedStagedFileIds(),
            linkedStagedRecords: this.getLinkedStagedRecordIds(),
            nextReviewStepId: overrideStageId,
            overrideComment: overrideComment,
        };
    }

    onSubmitError(error) {
        if (error.response.status === 403) {
            this.setState({submitError: 'You do not have permission to perform this action.'});
            return;
        }
        this.setState({submitError: error.message || error});
    }

    onSaveReviewStep(e) {
        e.stopPropagation();
        const data = this.getReviewData();
        this.setState({saving: true});
        BasicApi.put(`/api/reviews/${this.props.taskId}`, data)
            .then(() => {
                this.setState({
                    savedStatus: SavedStatus.Saved,
                    linkedStagedFilesUnsaved: [],
                    linkedStagedRecordsUnsaved: [],
                });
                this.loadScreenData(this.props.taskId);
            })
            .catch(this.onSubmitError)
            .finally(() => this.setState({saving: false}));
    }

    onRejectReviewStep() {
        const data = {
            rejectComment: this.state.rejectComment,
        };
        BasicApi.post(`/api/reviews/${this.props.taskId}/reject`, data)
            .then(() => {
                this.setState({
                    redirectToDashboard: true,
                });
            })
            .catch(this.onSubmitError);
    }

    onCompleteReviewStep() {
        if (!this.requestFormValidation()) {
            return;
        }
        const data = this.getReviewData();
        this.setState({saving: true});
        BasicApi.post(`/api/reviews/${this.props.taskId}`, data)
            .then(this.markSavedAndRedirectToDashboard)
            .catch(this.onSubmitError)
            .finally(() => this.setState({saving: false}));
    }

    onSuspendReviewStep(holdReason) {
        const data = this.getReviewData();
        data.holdReason = holdReason;
        this.setState({saving: true});
        BasicApi.put(`/api/reviews/${this.props.taskId}/suspend`, data)
            .then(() => {
                this.setState({
                    savedStatus: SavedStatus.Saved,
                    linkedStagedFilesUnsaved: [],
                    linkedStagedRecordsUnsaved: [],
                    processState: TaskProcessState.OnHold,
                    onHoldReason: holdReason,
                });
                // this.loadScreenData(this.props.taskId); TODO
            })
            .catch(this.onSubmitError)
            .finally(() => this.setState({saving: false}));
    }

    onOverrideReviewStep(overrideStageId, overrideComment) {
        if (!this.requestFormValidation()) {
            return;
        }
        const data = this.getOverrideReviewData(overrideStageId, overrideComment);
        this.setState({saving: true});
        BasicApi.post(`/api/reviews/${this.props.taskId}/override`, data)
            .then(this.markSavedAndRedirectToDashboard)
            .catch(this.onSubmitError)
            .finally(() => this.setState({saving: false}));
    }

    markSavedAndRedirectToDashboard() {
        this.setState({
            savedStatus: SavedStatus.Saved,
            redirectToDashboard: true,
        });
    }

    onLinkDocuments(type, documentArray) {
        if (this.isReviewStageNotStarted()) {
            this.startTask();
        }

        this.setState({savedStatus: SavedStatus.Unsaved});
        if (type === "files") {
            const mergedFiles = this.mergeLinkedDocuments(
                this.state.linkedStagedFilesUnsaved,
                this.formatFiles(documentArray),
                this.state.linkedStagedFiles,
            );
            this.setState({linkedStagedFilesUnsaved: mergedFiles});
        }

        if (type === "records") {
            const mergedRecords = this.mergeLinkedDocuments(
                this.state.linkedStagedRecordsUnsaved,
                this.formatRecords(documentArray),
                this.state.linkedStagedRecords,
            );
            this.setState({linkedStagedRecordsUnsaved: mergedRecords});
        }
    }

    onUnlinkDocument(type, documentId) {
        if (this.isReviewStageNotStarted()) {
            this.startTask();
        }

        this.setState({savedStatus: SavedStatus.Unsaved});

        if (type === "files") {
            this.unlinkFile(documentId);
        } else if (type === "records") {
            this.unlinkRecord(documentId);
        }
    }

    unlinkFile(stagedFileId) {
        const unsavedLinks = this.state.linkedStagedFilesUnsaved.filter(link => {
            return link.id !== stagedFileId;
        });
        const savedLinks = this.state.linkedStagedFiles.filter(link => {
            return link.id !== stagedFileId;
        });
        this.setState({
            linkedStagedFilesUnsaved: unsavedLinks,
            linkedStagedFiles: savedLinks,
        });
    }

    unlinkRecord(stagedRecordId) {
        const unsavedLinks = this.state.linkedStagedRecordsUnsaved.filter(link => {
            return link.id !== stagedRecordId;
        });
        const savedLinks = this.state.linkedStagedRecords.filter(link => {
            return link.id !== stagedRecordId;
        });
        this.setState({
            linkedStagedRecordsUnsaved: unsavedLinks,
            linkedStagedRecords: savedLinks,
        });
    }

    mergeLinkedDocuments(array1, array2, excludeArray) {
        const mergedArray = [...array1, ...array2];
        let set = new Set(excludeArray.map(item => item.id));
        return mergedArray.filter(item => {
            if (!set.has(item.id)) {
                set.add(item.id);
                return true;
            }
            return false;
        });
    }

    // Will be overwritten by GenericForm callback
    requestFormValidation() {
    }

    getSaveProgressButton() {
        if (!this.userIsAllocatedToTask()) {
            return null;
        }

        return (
            <Button
                secondary
                disabled={this.state.savedStatus !== SavedStatus.Unsaved || this.state.saving}
                onClick={this.onSaveReviewStep}
                loading={this.state.saving}
            >
                <Icon name="save" />
                Save Progress
            </Button>
        );
    }

    getPauseButton() {
        if (!this.userIsAllocatedToTask()) {
            return null;
        }

        return (
            <PauseButton
                onHold={this.state.processState === TaskProcessState.OnHold}
                holdReasons={this.state.holdReasons}
                onSuspendReview={this.onSuspendReviewStep}
            />
        );
    }

    getRejectStageButton() {
        const allowedPermissions = [
            Authority.RejectCase,
        ];
        if (!this.state.screenData.canRejectStep
            || (!userHasAnyPermission(allowedPermissions, this.props.userContext)
                && !this.userIsAllocatedToTask())) {
            return null;
        }

        return (
            <Button
                onClick={() => this.setState({showRejectModal: true})}
                disabled={this.state.saving}
            >
                <Icon name="undo" />
                Reject Stage
            </Button>
        );
    }

    getBreadcrumbs() {

        if (this.props.breadcrumbs) {
            return this.props.breadcrumbs;
        }
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Cases', content: 'My Cases'},
            {key: this.state.caseId, content: this.state.customerName},
        ];
    }

    userIsAllocatedToTask() {
        if (!this.state.screenData.allocatedTo) {
            return false;
        }
        return this.props.userContext.user.userId === this.state.screenData.allocatedTo.id;
    }

    getAnnotationModal(data) {
        const body = (
            <>
                The following review comments were made:
                <Divider />
                <Markdown text={data.text} />
            </>
        );
        return (
            <InformationModal
                title={data.title}
                body={body}
                trigger={
                    <Link to={"#"}>Click for details...</Link>
                }
            />
        );
    }

    renderAlerts() {
        let alerts = [];

        if (this.state.submitError) {
            alerts.push({
                level: AlertLevel.Error,
                header: "There was an error",
                content: this.state.submitError,
            });
        }
        if (!this.state.screenData.allocatedTo) {
            alerts.push({
                level: AlertLevel.Warning,
                heading: 'This case review stage has not been allocated',
                content: (
                    <div>
                        You will not be able to make changes to this case review stage
                        <ShowWithPermission permission={Authority.AllocateCase}>
                            <div>
                                <AllocateToMeButton
                                    userId={this.props.userContext.user.userId}
                                    taskId={this.props.taskId}
                                    reload={this.loadScreenData}
                                />
                            </div>
                        </ShowWithPermission>
                    </div>
                )
            });
        } else if (!this.userIsAllocatedToTask()) {
            alerts.push({
                level: AlertLevel.Warning,
                heading: 'This case review stage is not allocated to you',
                content: (
                    <div>
                        Only the allocated user can make changes to this case review stage
                        <ShowWithPermission permission={Authority.AllocateCase}>
                            <div>
                                <AllocateToMeButton
                                    userId={this.props.userContext.user.userId}
                                    taskId={this.props.taskId}
                                    reload={this.loadScreenData}
                                />
                            </div>
                        </ShowWithPermission>
                    </div>
                ),
            });
        }
        if (this.state.screenData.annotation) {
            alerts.push({
                level: AlertLevel.Information,
                heading: this.state.screenData.annotation.title,
                content: this.getAnnotationModal(this.state.screenData.annotation),
                className: "annotation-msg",
            });
        }

        return (
            <AlertMessageList alerts={alerts} />
        );
    }

    getReviewActionButtons() {
        if (this.userIsAllocatedToTask()) {
            return (
                <>
                    {this.getSaveProgressButton()}
                    {this.getPauseButton()}
                    <Button primary onClick={this.onCompleteReviewStep} disabled={this.state.saving}>
                        <Icon name="check" />
                        Complete Stage
                    </Button>
                </>
            );

        }

        return null;
    }

    getRejectModal() {
        return (
            <Modal open={this.state.showRejectModal}>
                <Modal.Header>
                    <Icon name="undo" />
                    Reject Stage: {this.state.screenData.title}
                </Modal.Header>
                <Modal.Content>
                    <p>Provide a comment as to why you are rejecting this stage:</p>
                    <FieldRichText
                        name="rejectComment"
                        value={this.state.rejectComment}
                        onChange={(n, value) => this.setState({rejectComment: value})}
                        placeholder="Reject Comment..."
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({showRejectModal: false})}>
                        <Icon name="remove" /> Cancel
                    </Button>
                    <Button secondary disabled={this.state.rejectComment === ''} onClick={this.onRejectReviewStep}>
                        <Icon name="undo" /> Reject
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        if (this.state.loadingData) {
            return <Loader active />;
        }

        if (this.state.redirectToDashboard) {
            return <Redirect
                to={{
                    pathname: getCaseListRoute(getProgrammeIdFromCurrentPath()),
                    state: {delayedRefresh: true},
                }}
            />;
        }

        return (
            <div ref={this.contextRef}>
                {this.getRejectModal()}
                <Container fluid textAlign="left" className="review-task">
                    <Container fluid style={{marginBottom: '10px'}}>
                        {this.renderAlerts()}
                    </Container>
                    <Divider hidden />
                    <ReviewStageFixedHeader
                        reviewId={this.state.screenData.caseReviewStepId}
                        contextRef={this.contextRef}
                        renderSaveBtn={this.getSaveProgressButton}
                        renderRejectBtn={this.getRejectStageButton}
                        renderHoldBtn={this.getPauseButton}
                        savedState={this.state.savedStatus}
                        holdReason={this.state.onHoldReason}
                        stageStatus={this.state.processState}
                    />
                    {this.state.referenceData.length > 0 &&
                        <ReferenceData data={this.state.referenceData} />
                    }
                    <Divider hidden />
                    <RulesGenericForm
                        schema={this.state.schema}
                        onFormDataChanged={this.onFormDataChanged}
                        formData={this.state.formData}
                        navigationPrompt={false}
                        showButtons={false}
                        styleClass="left-align-fields"
                        registerValidateFunctionCallback={formValidationFunc => this.requestFormValidation = formValidationFunc}
                    />
                    <StagingRecordLinks
                        linkedFiles={this.state.linkedStagedFiles}
                        unsavedLinkedFiles={this.state.linkedStagedFilesUnsaved}
                        linkedRecords={this.state.linkedStagedRecords}
                        unsavedLinkedRecords={this.state.linkedStagedRecordsUnsaved}
                        allowNew={this.state.allowAddStagedFile}
                        caseId={this.state.caseId}
                        taskId={this.props.taskId}
                        reviewStageId={this.state.screenData.caseReviewStepId}
                        onError={this.onSubmitError}
                        onLinkDocuments={this.onLinkDocuments}
                        onUnlinkDocument={this.onUnlinkDocument}
                    />

                    <Divider />
                    <Header as="h3">Next Stage</Header>
                    <NextStageDisplay taskId={this.props.taskId} reviewData={this.state.formData} />
                    {this.getReviewActionButtons()}
                    {this.userIsAllocatedToTask() &&
                        <OverrideStageComponent
                            onOverrideStage={this.onOverrideReviewStep}
                            overrideStages={this.state.bypassStages}
                        />
                    }
                </Container>
            </div>
        );
    }
}

CaseReviewStage.propTypes = {
    taskId: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

CaseReviewStage.defaultProps = {
    breadcrumbs: undefined,
};

export default withUserContextProp(CaseReviewStage);
