function PasswordGenerator(length: number): string {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*+-_~';
    const indexes = crypto.getRandomValues(new Uint32Array(length));

    let password = '';

    //@ts-ignore
    for (const index of indexes) {
        password += charset[index % charset.length];
    }

    return password;
}

export default PasswordGenerator;