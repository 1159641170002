import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Loader, Message, Table } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import { Link, withRouter } from 'react-router-dom';
import 'components/reports/RedressReport.scss';
import { getCaseRoute } from 'components/case/CaseRouter';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const EMPTY_SAMPLE_TEXT = '-';
const LOCALE_OPTIONS = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

const AccountingValue = ({value, prefix = ''}) => {
    if (value < 0) {
        return <span className="accounting-value">({Math.abs(value).toLocaleString(undefined, LOCALE_OPTIONS)})</span>;
    }

    let displayValue = value;
    if (!isNaN(displayValue) && (displayValue !== null)) {
        displayValue = value.toLocaleString(undefined, LOCALE_OPTIONS);
    } else {
        displayValue = EMPTY_SAMPLE_TEXT;
    }
    return (
        <span className="accounting-value offset">{prefix}{displayValue}</span>
    );
};

AccountingValue.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

class RedressReport extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            data: null,
            loading: false,
            error: null,
            initialDate: new Date(),
        };
    }

    componentDidMount() {
        this.fetchReport();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.fetchReport();
        }
    }

    fetchReport() {
        this.setState({loading: true});
        const query = {
            programmeId: getProgrammeIdFromCurrentPath()
        };

        BasicApi.getWithQuery(`/api/reports/redress`, query)
            .then(this.setReportData)
            .catch(this.fetchError)
            .finally(() => this.setState({loading: false}));
    }

    fetchError(error) {
        this.setState({error: error.message});
    }

    setReportData(data) {
        this.setState({
            data: data,
            error: null,
        });
    }

    getSingleCustomField(customers, field) {
        if (customers.length === 0) {
            return '';
        }
        return customers[0].customAttributes[field];
    }

    renderTable() {
        return (
            <Table className="redress" collapsing compact unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Case Number</Table.HeaderCell>
                        <Table.HeaderCell>Customer Number(s)</Table.HeaderCell>
                        <Table.HeaderCell>Customer Name(s)</Table.HeaderCell>
                        <Table.HeaderCell>Australian Financial Services Licensee (AFSL)</Table.HeaderCell>
                        <Table.HeaderCell>AFSL ABN Number</Table.HeaderCell>
                        <Table.HeaderCell>AFSL License Number</Table.HeaderCell>
                        <Table.HeaderCell>Advice Fees</Table.HeaderCell>
                        <Table.HeaderCell>Interest (TVM)</Table.HeaderCell>
                        <Table.HeaderCell>Total Redress</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.state.data.pagedEntries.map(item => (
                        <Table.Row key={item.caseId}>
                            <Table.Cell>
                                <Link
                                    to={`${getCaseRoute(
                                        getProgrammeIdFromCurrentPath(),
                                        item.caseId,
                                    )}`}
                                >{item.displayId}</Link>
                            </Table.Cell>
                            <Table.Cell>
                                {item.customers.map(customer => (
                                    <div key={customer.customerId}>
                                        <Link
                                            to={`${getCustomerRoute(
                                                getProgrammeIdFromCurrentPath(),
                                                customer.customerId,
                                            )}`}
                                        >
                                            {customer.customerReferenceNumber}
                                        </Link>
                                    </div>
                                ))}
                            </Table.Cell>
                            <Table.Cell>
                                {item.customers.map(customer => (
                                    <div key={customer.customerId}>
                                        <Link
                                            to={`${getCustomerRoute(
                                                getProgrammeIdFromCurrentPath(),
                                                customer.customerId,
                                            )}`}
                                        >
                                            {customer.customerName}
                                        </Link>
                                    </div>
                                ))}
                            </Table.Cell>
                            <Table.Cell>{this.getSingleCustomField(
                                item.customers,
                                'ausFinServicesLicensee',
                            )}</Table.Cell>
                            <Table.Cell>{this.getSingleCustomField(
                                item.customers,
                                'ausFinServicesLicenseeAbn',
                            )}</Table.Cell>
                            <Table.Cell>{this.getSingleCustomField(
                                item.customers,
                                'ausFinServicesLicenseeNum',
                            )}</Table.Cell>
                            <Table.Cell textAlign="right"><AccountingValue value={item.redressAmounts.adviceFees} /></Table.Cell>
                            <Table.Cell textAlign="right"><AccountingValue value={item.redressAmounts.interest} /></Table.Cell>
                            <Table.Cell textAlign="right"><AccountingValue value={item.redressTotal} /></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row className="redressTotal">
                        <Table.HeaderCell colSpan={6}>Total Redress/Compensation</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right"><AccountingValue
                            prefix="$"
                            value={this.state.data.totalRedressAmounts.adviceFees}
                        /></Table.HeaderCell>
                        <Table.HeaderCell textAlign="right"><AccountingValue
                            prefix="$"
                            value={this.state.data.totalRedressAmounts.interest}
                        /></Table.HeaderCell>
                        <Table.HeaderCell textAlign="right"><AccountingValue
                            prefix="$"
                            value={this.state.data.redressTotal}
                        /></Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }

    renderErrorMessage() {
        if (this.state.error) {
            return (
                <Message negative>
                    <Message.Header>There was an error generating the report</Message.Header>
                    <p>{this.state.error}</p>
                </Message>
            );
        }
        return null;
    }

    renderInformationMessage() {
        if (this.state.data && this.state.data.informationMessage) {
            return (
                <Message info>
                    {/*<Message.Header>No data available</Message.Header>*/}
                    <p>{this.state.data.informationMessage}</p>
                </Message>
            );
        }
        return null;
    }

    render() {
        return (
            <>
                <Loader active={this.state.loading} />
                {this.renderErrorMessage()}
                {this.renderInformationMessage()}
                {this.state.data && this.renderTable()}
            </>
        );
    }
}

RedressReport.propTypes = {};

RedressReport.defaultProps = {};

export default withRouter(RedressReport);
