import React, { useState } from 'react';
import { Button, Divider, Form, Header, Icon, List, Message, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import BasicApi from 'api/BasicApi';

const PasswordChangeModal = (): JSX.Element => {

    const CHANGE_PASSWORD_ROUTE = '/api/user/password';
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(true);
    const [currentPasswordInvalid, setCurrentPasswordInvalid] = useState<boolean>(false);
    const [newPasswordsNotIdentical, setNewPasswordsNotIdentical] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string | undefined>(undefined);
    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string[] | undefined>(undefined);

    function onClose() {
        setShowModal(false);
        history.goBack();
    }


    function handleSubmit(): void {
        if (newPasswordConfirmation !== newPassword) {
            setNewPasswordsNotIdentical(true);
            return;
        }

        BasicApi.put(CHANGE_PASSWORD_ROUTE, {currentPassword, newPassword})
            .then(() => {
                handlePasswordChangeSuccess();
            })
            .catch((err) => {
                setApiError(err.messageList);
            });
    }

    function handlePasswordChangeSuccess(): void {
        setSuccess(true);
    }

    function handleCurrentPasswordChange(event: React.FormEvent<HTMLInputElement>): void {
        setCurrentPasswordInvalid(false);
        setSuccess(false);
        setCurrentPassword(event.currentTarget.value);
    }

    function handleNewPasswordChange(event: React.FormEvent<HTMLInputElement>): void {
        setNewPassword(event.currentTarget.value);
        setSuccess(false);
        setNewPasswordsNotIdentical(false);
    }

    function handleNewPasswordConfirmationChange(event: React.FormEvent<HTMLInputElement>): void {
        setNewPasswordConfirmation(event.currentTarget.value);
        setSuccess(false);
        setNewPasswordsNotIdentical(false);
    }

    return (
        <Modal open={showModal} size="tiny" closeOnEscape onClose={onClose} centered={false}>
            <Header as="h3" textAlign="left">
                Change your password
            </Header>
            <Modal.Content>
                {!success && <Modal.Description>
                    {apiError &&
                        <Message error>
                            <Message.Header>Error Submitting new Password:</Message.Header>
                            <List bulleted>
                                {apiError.map((error, index) => (
                                    <List.Item key={index} className="split-newline">{error}</List.Item>
                                ))}
                            </List>
                        </Message>
                    }
                    <Form size="large" widths="equal">
                        <Form.Field>
                            <Form.Input
                                label="Current password"
                                labelPosition="left"
                                fluid icon="lock"
                                iconPosition="left"
                                type="password"
                                name="oldpassword"
                                placeholder="Current password"
                                onChange={handleCurrentPasswordChange}
                                autoComplete="password"
                                error={currentPasswordInvalid && 'Current password is invalid'}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Input
                            label="New password"
                            labelPosition="left"
                            fluid icon="lock"
                            type="password"
                            iconPosition="left"
                            name="new-password"
                            placeholder="New password"
                            onChange={handleNewPasswordChange}
                        />
                        <Form.Input
                            label="Confirm new password"
                            labelPosition="left"
                            fluid icon="lock"
                            type="password"
                            iconPosition="left"
                            name="new-password-confirmation"
                            placeholder="New password confirmation"
                            onChange={handleNewPasswordConfirmationChange}
                            error={newPasswordsNotIdentical
                                && 'New and confirmation passwords must be provided and must be identical.'}
                        />
                    </Form>
                </Modal.Description>
                }
                {success &&
                    <Message positive>
                        <Message.Header>Your password has been changed</Message.Header>
                    </Message>
                }
            </Modal.Content>
            <Modal.Actions>
                {!success && <Button size="large" onClick={() => onClose()}>
                    <Icon name="remove" /> Cancel
                </Button>}

                {!success && <Button primary size="large" onClick={handleSubmit}>
                    <Icon name="checkmark" /> Change Password
                </Button>}
                {success && <Button primary size="large" onClick={() => onClose()}>
                    Done
                </Button>}

            </Modal.Actions>


        </Modal>

    );
};

export default PasswordChangeModal;