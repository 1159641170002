import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Container, Icon, Loader, Segment, Step } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import BasicApi from 'api/BasicApi';
import BypassAnnotation from 'components/case/reviews/BypassAnnotation';
import GenericForm from 'common/form/SemanticUIForm';
import { getReadOnlyFormSchema } from 'common/util/FormHelpers';
import { ISOStringToFriendlyDateTime } from 'common/util/DateUtils';
import ReviewStageFixedHeader from 'components/case/reviews/ReviewStageFixedHeader';
import { Breadcrumbs } from 'components/common/PageHeader';
import CaseRecordLinks from 'components/case/CaseRecordLinks';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';

class ReviewDetails extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: undefined,
            screenData: undefined,
            loadingData: true,
            saving: false,
        };
        this.ref = createRef();
    }

    componentDidMount() {
        this.loadScreenData(this.props.reviewStepId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.reviewStepId !== prevProps.reviewStepId) {
            this.loadScreenData(this.props.reviewStepId);
        }
    }

    loadScreenData(taskId) {
        this.setState({loadingData: true});
        BasicApi.get(`/api/review-steps/${taskId}`)
            .then(this.setScreenData)
            .catch(BasicApi.logError)
            .finally(() => this.setState({loadingData: false}));
    }

    setScreenData(data) {
        this.setState(data);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Cases', content: 'My Cases'},
            {key: 'Case', content: `Case Number: ${this.props.displayId}`},
            {key: 'ReviewStageHistory', content: `${this.state.title} (History)`},
        ];
    }

    getClosureActionIcon(closureAction) {
        switch (closureAction) {
            case 'Bypassed':
                return 'share';
            case 'CompletedWithOverride':
                return 'stop';
            case 'Cancelled':
                return 'cancel';
            case 'Rejected':
                return 'undo';
            default:
                return 'stop';
        }
    }

    getClosureText(closureAction) {
        switch (closureAction) {
            case 'CompletedWithOverride':
                return 'Completed (with Override)';
            default:
                return closureAction || 'Closed';
        }
    }

    getReviewStageSteps() {
        const steps = [
            {
                key: 'Started',
                active: true,
                icon: 'play',
                title: 'Started',
                description: ISOStringToFriendlyDateTime(this.state.startTime),
            },
        ];
        if (this.state.allocatedTo) {
            steps.push({
                    key: 'Allocated',
                    active: true,
                    icon: 'user plus',
                    title: 'Allocated',
                    description: this.state.allocatedTo.name,
                },
            );
        }
        steps.push({
            key: 'Closed',
            active: true,
            icon: this.getClosureActionIcon(this.state.closureAction),
            title: this.getClosureText(this.state.closureAction),
            description: ISOStringToFriendlyDateTime(this.state.finishTime),
        });
        return steps;
    }

    render() {
        if (this.state.loadingData) {
            return <Loader active />;
        }

        return (
            <div ref={this.ref}>
                <Container fluid textAlign="left">
                    <Breadcrumbs sections={this.getBreadcrumbs()} />
                    <br />
                    <ReviewStageFixedHeader reviewId={this.props.reviewStepId} contextRef={this.ref} />
                    <Segment attached>
                        <Step.Group size="mini" fluid items={this.getReviewStageSteps()} />
                        <BypassAnnotation data={this.state.annotation} />
                    </Segment>

                    <Segment attached="bottom">
                        <GenericForm
                            schema={getReadOnlyFormSchema(this.state.schema)}
                            formData={this.state.fieldData}
                            showButtons={false}
                        />
                    </Segment>

                    <CaseRecordLinks
                        linkedFiles={this.state.linkedStagedFiles}
                        linkedRecords={[]}
                    />

                </Container>
            </div>
        );
    }
}

ReviewDetails.propTypes = {
    reviewStepId: PropTypes.string.isRequired,
};

export default withRouter(ReviewDetails);
