import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { Loader, Message, Segment } from 'semantic-ui-react';
import PageHeader from 'components/common/PageHeader';
import GenericForm from 'common/form/SemanticUIForm';
import BasicApi from 'api/BasicApi';
import { withRouter } from 'react-router-dom';
import { withConfiguration } from 'components/common/ConfigurationContext';

const ICON_OPTIONS = [
    "calculator",
    "book",
    "chart line",
    "tasks",
    "suitcase",
    "address card",
    "dollar sign",
    "briefcase",
    "users",
    "home",
    "car",
    "money",
    "handshake",
];

const DEFAULT_FORM_DATA = {
    restricted: true,
};

class ProgrammeForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: this.getFormSchema(),
            formData: DEFAULT_FORM_DATA,
            loading: false,
            error: '',
        };
    }

    componentDidMount() {
        this.getFields();
    }

    async getFields() {
        this.setState({loading: true});
        await this.getWorkflows();
        this.setSchema([]);

        if (this.props.editProgrammeId) {
            await this.getProgramme();
        }
        this.setState({loading: false});
    }

    getWorkflows() {
        BasicApi.get(`/api/workflows`)
            .then(this.setSchema);
    }

    getIcons() {
        return ICON_OPTIONS.map(group => {
            return {
                id: group,
                text: group,
                icon: group,
            };
        });
    }

    getProgramme() {
        return BasicApi.get(`/api/programmes/${this.props.editProgrammeId}`)
            .then(this.setFormData);
    }

    setFormData(data) {
        this.setState({
            formData:
                {
                    name: data.programmeName,
                    prefix: data.prefix,
                    suffix: data.suffix,
                    description: data.description,
                    icon: data.icon,
                    workflow: data.workflowId,
                    restricted: data.restricted,
                    archived: data.archived,
                },
        });
    }

    setSchema(data) {
        const icons = this.getIcons();
        const workflows = [
            ...data.map(workflow => {
                return {
                    id: workflow.workflowId,
                    text: workflow.workflowId,
                    programmeOfWork: workflow.programmeOfWork,
                };
            }),
        ];

        this.setState({
            schema: this.getFormSchema(
                icons,
                workflows,
            ),
        });
    }

    getBreadcrumbs() {
        const crumbs = [
            {key: 'Administration', content: 'Administration'},
            {key: 'ProgramOfWork', content: 'Programs of Work'},
        ];

        if (this.props.editProgrammeId) {
            crumbs.push({key: 'Edit Program', content: 'Edit Program of Work'});
        } else {
            crumbs.push({key: 'New Program', content: 'New Program of Work'});
        }

        return crumbs;
    }

    getFormSchema(icons, workflows) {
        let filteredWorkflows = workflows;
        if (workflows) {
            filteredWorkflows = workflows.filter(wf =>
                (this.props.createMode && !wf.programmeOfWork) ||
                (!this.props.createMode &&
                    this.state.formData &&
                    this.state.formData.workflow &&
                    wf.id === this.state.formData.workflow),
            )
        }
        const schema = {
            fields: {
                name: {
                    type: 'text',
                    label: 'Name',
                    placeHolder: '',
                    required: true,
                },
                prefix: {
                    type: 'text',
                    label: 'Code (Prefix)',
                    placeHolder: '',
                    required: true,
                },
                suffix: {
                    type: 'text',
                    label: 'Suffix',
                    placeHolder: '',
                    required: false,
                },
                description: {
                    type: 'plaintextarea',
                    label: 'Description',
                    required: true,
                    placeholder: '',
                },
                icon: {
                    type: 'select',
                    label: 'Icon',
                    required: true,
                    placeholder: '',
                    options: icons,
                },
                workflow: {
                    type: 'select',
                    label: 'Workflow',
                    required: this.props.createMode,
                    disabled: !this.props.createMode,
                    options: filteredWorkflows,
                },
                restricted: {
                    type: 'checkbox',
                    label: 'Restricted',
                    required: false,
                    placeHolder: '',
                },
            },
            fieldOrder: [
                'name',
                'prefix',
                'suffix',
                'description',
                'icon',
                'workflow',
                'restricted',
            ],
        };

        if (this.props.editProgrammeId) {
            return {
                fields: {
                    ...schema.fields,
                    archived: {
                        type: 'checkbox',
                        label: 'Archived',
                        required: false,
                        placeHolder: '',
                    },
                },
                fieldOrder: [
                    ...schema.fieldOrder,
                    'archived',
                ],
            };
        }
        return schema;
    }

    onFormDataChanged(data) {
        this.setState({formData: data});
    }

    onSubmit(formData) {
        return new Promise((resolve, reject) => {
            this.setState({
                saving: true,
            });

            if (!this.props.editProgrammeId) {
                BasicApi.post(`/api/programmes`, formData)
                    .then(resolve)
                    .catch(err => {
                        reject("Error Adding new Program");
                        console.error(err);
                    });
            } else {
                BasicApi.put(`/api/programmes/${this.props.editProgrammeId}`, formData)
                    .then(resolve)
                    .catch(err => {
                        reject("Error Updating Programme");
                        console.error(err);
                    });
            }
        });

    }

    onCancel() {
        this.props.redirect();
    }

    onSubmitSuccess() {
        this.setState({saving: false});
        this.props.configurationData.reloadProgrammes();
        this.props.redirect();
    }

    onError(error) {
        this.setState({
            error: `Error while ${this.props.editProgrammeId ? "editing" : "creating"} program`,
            saving: false,
        });
    }

    render() {
        if (this.state.loading || this.state.loadingConfig) {
            return <Loader active />;
        }
        return (
            <>
                <PageHeader
                    icon="balance scale"
                    text={this.props.editProgrammeId ? "Edit Program of Work" : "Create Program of Work"}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                {this.state.error &&
                    <Message negative>
                        <Message.Header>Could not create Program</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>
                }
                <Segment attached="bottom">
                    <GenericForm
                        schema={this.state.schema}
                        onFormDataChanged={this.onFormDataChanged}
                        formData={this.state.formData}
                        navigationPrompt={false}
                        showButtons={true}
                        styleClass="left-align-fields"
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        onSubmitSuccess={this.onSubmitSuccess}
                        onSubmitError={this.onError}
                        submitButtonDisabled={this.state.saving}
                    />
                </Segment>
            </>
        );
    }
}

export default withConfiguration(withRouter(ProgrammeForm));
