import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import GenericForm from 'common/form/SemanticUIForm';
import cloneDeep from 'lodash/cloneDeep';
import { Loader, Message, Button, Segment } from "semantic-ui-react";
import BasicApi from 'api/BasicApi';
import { withUserContextProp } from 'UserContext';
import Authority from 'common/auth/Authority';
import UserQRCode from 'common/auth/UserQRCode';
import PageHeader from 'components/common/PageHeader';
import DeleteUserButton from 'components/admin/DeleteUserButton';
import EditUserPasswordModal from 'components/admin/EditUserPasswordModal';

const getFormSchema = (groups, userActive) => {
    return {
        fields: {
            userId: {
                type: 'text',
                label: 'User Id',
                placeHolder: '',
                disabled: true,
            },
            username: {
                type: 'text',
                label: 'Username',
                placeHolder: '',
                disabled: true,
            },
            name: {
                type: 'text',
                label: 'Display Name',
                placeHolder: '',
                disabled: true,
            },
            groupIds: {
                type: 'multiselect',
                label: 'Groups',
                required: false,
                placeHolder: '',
                options: groups,
                disabled: !userActive,
            },
            mfaEnabled: {
                type: 'toggle',
                label: 'Enable 2-factor authentication',
                placeHolder: 'Enable 2-factor authentication',
                required: true,
                disabled: !userActive,
            },
        },
        fieldOrder: [
            'userId',
            'username',
            'name',
            'groupIds',
            'mfaEnabled',
        ],
    };
};

const InitialFormData = {
    userId: '',
    groupIds: [],
};

class UserEditForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: cloneDeep(getFormSchema([])),
            formData: cloneDeep(InitialFormData),
            groupIds: [],
            groups: [],
            loading: false,
            error: undefined,
            success: undefined,
            isPasswordModalOpen: false,
        };
    }

    componentDidMount() {
        this.loadGroupsAndDetails();
    }

    onError(error) {
        this.setState({error: error.message || error});
    }

    loadGroups() {
        return BasicApi.get(`/api/groups`)
            .then(this.setGroups);
    }

    loadUserDetails() {
        return BasicApi.get(`/api/users/${this.props.userId}`)
            .then(this.setUserData);
    }

    loadGroupsAndDetails() {
        this.setState({loading: true});
        Promise.all([
                this.loadUserDetails(this.props.userId),
                this.loadGroups(),
            ])
            .then(this.setSchema)
            .catch(this.onError)
            .finally(() => this.setState({loading: false}));
    }

    isCurrentAdmin() {
        // Return true if we are editing the currently logged in administrative user
        // Note: As the moment, userId is synonymous with username but may not always be the case
        return this.props.userId === this.props.userContext.user.username
            && this.props.userContext.hasPermissions(Authority.UserPermissions);
    }

    setGroups(data) {
        this.setState({groups: data});
    }

    setSchema() {
        const fieldOptions = this.state.groups.map(group => {
            return {
                id: group.id,
                text: group.name,
            };
        });

        this.setState({
            schema: cloneDeep(getFormSchema(fieldOptions, this.state.userActive)),
        });
    }

    setUserData(data) {
        const groupIds = data.groups.map((group) => {
            return group.id;
        });
        this.setState({
            formData: {
                userId: this.props.userId,
                username: data.username,
                name: data.name,
                groupIds: groupIds,
                mfaEnabled: data.mfaEnabled,
            },
            userActive: data.active,
        });
    }

    onFormDataChanged(formData) {
        this.setState({
            formData: formData,
        });
    }

    onSubmitSuccess() {
        this.setState({
            saving: false,
        });
        this.props.redirect();
    }

    onSubmitError(error) {
        this.setState({
            saving: false,
        });
        this.onError(error);
    }

    onSubmit(formData) {
        this.setState({
            saving: true,
        });
        return BasicApi.put(`/api/users/${this.props.userId}`, formData);
    }

    onCancel() {
        this.props.redirect();
    }

    getName() {
        return this.state.formData.name ?? "New User";
    }

    getBreadcrumbs() {
        const userName = this.props.userId ? this.props.userId : 'New User';
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Users', content: 'Users'},
            {key: userName, content: userName},
        ];
    }

    getButtons() {
        if (!this.state.userActive) {
            return;
        }

        return <>
            {this.state.isPasswordModalOpen &&
                <EditUserPasswordModal
                    close={() => this.setState({isPasswordModalOpen: false})}
                    user={{
                        id: this.props.userId,
                        name: this.state.formData.username,
                        displayName: this.state.formData.name,
                    }}
                    setError={this.onError}
                    onSuccess={(successMessage) => this.setState({success: successMessage})}
                />
            }
            <DeleteUserButton
                userId={this.props.userId}
                setError={this.onError}
                onDeleteSuccess={this.loadGroupsAndDetails}
            />
            <Button
                primary
                icon={'edit'}
                onClick={() => this.setState({isPasswordModalOpen: true})}
                content="Change Password"
            />
        </>;
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }
        const header = `Edit User: ${this.getName()} ${this.isCurrentAdmin() ? '(Administrator)'
            : ''} ${this.state.userActive ? '' : '(inactive)'}`;
        return (
            <>
                <PageHeader
                    icon="user"
                    text={header}
                    breadcrumbs={this.getBreadcrumbs()}
                    headerRightComponent={this.getButtons()}
                />
                <Segment attached={"bottom"}>
                    {this.state.error &&
                        <Message negative>
                            <Message.Header>Could not update user</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                    }
                    {this.state.success &&
                        <Message positive>
                            <Message.Header>User update Successful</Message.Header>
                            <p>{this.state.success}</p>
                        </Message>
                    }
                    <GenericForm
                        schema={this.state.schema}
                        onFormDataChanged={this.onFormDataChanged}
                        formData={this.state.formData}
                        navigationPrompt={false}
                        showButtons={this.state.userActive}
                        styleClass="left-align-fields"
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        onSubmitError={(error) => this.onSubmitError(error)}
                        onSubmitSuccess={this.onSubmitSuccess}
                        submitButtonDisabled={this.state.saving}
                    />
                    {this.state.formData.mfaEnabled && <UserQRCode userId={this.props.userId} />}
                </Segment>
            </>
        );
    }
}

UserEditForm.propTypes = {
    userId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    userContext: PropTypes.shape({
        hasPermissions: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(withUserContextProp(UserEditForm));