import React from 'react';
import { Route, Switch } from "react-router-dom";
import AppAdminPage from 'components/admin/AppAdminPage';
import UserList from 'components/admin/UserList';
import GroupList from 'components/admin/GroupList';
import AuditLog from 'components/admin/AuditLog';
import RequireAnyPermission from 'common/auth/RequirePermissions';
import { AdminScreenPermissions } from 'common/auth/Authority';
import { subRoute } from 'common/util/RouteHelpers';
import UserEditForm from 'components/admin/UserEditForm';
import NewUserForm from 'components/admin/NewUserForm';
import ProgrammesList from 'components/admin/ProgrammesList';
import DataLoadRouter from 'components/admin/dataLoad/DataLoadRouter';
import CustomerRouter from 'components/customer/CustomerRouter';
import ReportRouter from 'components/reports/ReportRouter';
import ProgrammeForm from 'components/admin/ProgrammeForm';

const AppAdminRouter = ({match}) => (
    <RequireAnyPermission permissions={AdminScreenPermissions}>
        <Switch>
            <Route
                exact
                path={match.url}
                render={({match}) => (<AppAdminPage />)}
            />

            <Route
                path={subRoute(match, '/users')}
                exact
                render={({match}) => (<UserList />)}
            />

            <Route
                path={subRoute(match, '/users/new')}
                exact
                render={({history}) => (
                    <NewUserForm
                        redirect={() => history.go(-1)}
                    />
                )}
            />
            <Route
                path={subRoute(match, '/users/:userId')}
                exact
                render={({match: currentMatch, history}) => (
                    <UserEditForm
                        userId={currentMatch.params.userId}
                        redirect={() => history.go(-1)}
                    />
                )}
            />

            <Route path={subRoute(match, '/groups')} exact>
                <GroupList />
            </Route>

            <Route path={subRoute(match, '/audit')} exact>
                <AuditLog />
            </Route>

            <Route
                path={subRoute(match, '/programmes')}
                exact
                render={({match}) => (
                    <ProgrammesList />
                )}
            >
            </Route>

            <Route
                path={subRoute(match, '/programmes/new')}
                render={({history}) => (
                    <ProgrammeForm
                        createMode={true}
                        redirect={() => history.go(-1)}
                    />
                )}
            />
            <Route
                path={subRoute(match, '/programmes/edit/:editProgrammeId')}
                render={({match: currentMatch, history}) => (
                    <ProgrammeForm
                        createMode={false}
                        editProgrammeId={currentMatch.params.editProgrammeId}
                        redirect={() => history.go(-1)}
                    />
                )}
            />
            <Route path={subRoute(match, '/customers')} component={CustomerRouter} />

            <Route path={subRoute(match, '/reports')} component={ReportRouter} />

            <DataLoadRouter match={match} />
        </Switch>
    </RequireAnyPermission>
);

export {
    AppAdminRouter as default
};
