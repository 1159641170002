import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Link } from 'react-router-dom';
import { Icon, Label, Table } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import UnderConstructionWarning from 'components/common/UnderConstructionWarning';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import withProgramme from 'components/programme/ProgrammeProps';

class FieldControl extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            permissions: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.loadSummary();
    }

    loadSummary() {
        this.setState({loading: true});
        BasicApi.get(`/noauth/field-permissions`)
            .then(this.setPermissions)
            .finally(() => this.setState({loading: false}));
    }

    setPermissions(data) {
        this.setState({
            permissions: data,
        })
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'ProgramAdministration', content: 'Program Administration'},
            {key: 'Field Control', content: 'Field Control'},
        ];
    }

    render() {
        return (
            <React.Fragment>
                <UnderConstructionWarning />
                <PageHeader
                    text="Field Control"
                    icon={<LoaderIcon icon="lock" onClick={this.loadSummary} loading={this.state.loading} />}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                <Table attached="bottom" selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Default Permission</Table.HeaderCell>
                            <Table.HeaderCell>Permission Entries</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.permissions.map(permission => (
                            <Table.Row key={`permssion-${permission.fieldId}`}>
                                <Table.Cell>
                                    {permission.fieldName}
                                </Table.Cell>
                                <Table.Cell>
                                    <Label>
                                        {permission.defaultPermission}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    {permission.permissionCount}
                                </Table.Cell>
                                <Table.Cell>
                                    <Link to={`/admin/fields/${permission.fieldId}`} className="nowrap">
                                        Edit &raquo;
                                    </Link>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </React.Fragment>
        );
    }
}

FieldControl.propTypes = {
    children: PropTypes.node,
};

FieldControl.defaultProps = {
    children: undefined,
};

export default withProgramme(FieldControl);