import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Card, Loader } from "semantic-ui-react";
import BasicApi from 'api/BasicApi';
import { Link } from 'react-router-dom';
import { ISOStringToFriendlyDateTime } from 'common/util/DateUtils';
import { pascalCaseWithSpaces } from 'common/util/StringHelpers';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

class CaseDetails extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            caseData: undefined,
            loading: false,
        };
    }

    componentDidMount() {
        this.getCaseDetails();
    }

    getCaseDetails() {
        this.setState({loading: true});
        BasicApi.get(`/api/cases/${this.props.caseId}`)
            .then(this.setCaseDetails)
            .catch(BasicApi.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCaseDetails(data) {
        this.setState({caseData: data});
    }

    render() {
        if (this.state.loading || !this.state.caseData) {
            return <Loader active />;
        }

        // TODO: Hardcoded Programme of Work below
        const {caseData} = this.state;
        return (
            <>
                <Card fluid>
                    <Card.Content>
                        <Card.Header>Case Number: {caseData.displayId}</Card.Header>
                        <Card.Meta>Created by {caseData.startedBy.name}</Card.Meta>
                        <Card.Meta>Started on {ISOStringToFriendlyDateTime(caseData.startTime)}</Card.Meta>

                    </Card.Content>
                    <Card.Content>
                        <Card.Header>Customer(s)</Card.Header>
                        {caseData.customers.map(customer => (
                            <div key={customer.customerId}>
                                <Card.Description>
                                    <Link
                                        to={`${getCustomerRoute(
                                            getProgrammeIdFromCurrentPath(),
                                            customer.customerId,
                                        )}`}
                                    >{customer.customerName}</Link>
                                </Card.Description>
                                <Card.Meta>Customer Number: {customer.customerReferenceNumber}</Card.Meta>
                            </div>
                        ))
                        }
                    </Card.Content>
                    <Card.Content>
                        <Card.Header>Current Stage</Card.Header>
                        <Card.Description>
                            {caseData.stageScreenName}
                        </Card.Description>
                        {caseData.allocatedTo && <Card.Meta>Allocated To: {caseData.allocatedTo.name}</Card.Meta>}
                        {!caseData.allocatedTo && <Card.Meta>Unallocated</Card.Meta>}
                    </Card.Content>
                    <Card.Content>
                        <Card.Header>Case Status: {pascalCaseWithSpaces(caseData.status)}</Card.Header>
                    </Card.Content>
                </Card>
            </>
        );
    }
}

CaseDetails.propTypes = {
    caseId: PropTypes.string.isRequired,
};

CaseDetails.defaultProps = {};

export default CaseDetails;