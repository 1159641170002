import bindClassMethods from 'common/util/AutoBind';
import ApiError from 'api/ApiError';

const LOGIN_ROUTE = '/api/auth/basic?remember-me=true';
const LOGOUT_ROUTE = '/api/auth/logout';
const CONTEXT_ROUTE = '/api/auth/context';
const LOGIN_PATH = '/login';

// CSRF Header used with NCL-Auth
const CSRF_HEADER = {'X-REQUEST-NRT': 'Protect'};
const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    /**
     * The SameSite header is inserted by default when unspecified in modern browsers
     * it is manually inserted here to be explicit and ensure consistency
     * across different browser versions
     */
    'SameSite': 'Lax',
    ...CSRF_HEADER,
};

enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export interface ErrorResponse {
    messageList?: string[],
    message?: string,
}

// Monkey Patch Fetch to Intercept All HTTP Requests/Responses
// Hot reload does not work for this, refresh your browser after making changes
const {fetch: originalFetch} = window;
window.fetch = async (resource: RequestInfo | URL, incomingConfig?: RequestInit) => {
    // intercept request
    let config = incomingConfig ?? {};
    config.headers = {
        ...config!.headers,
        ...DEFAULT_HEADERS,
    };

    let response = await originalFetch(resource, config);
    // intercept response

    return response;
};

/**
 * Class for performing basic api requests <br/>
 *
 * rejects with {@link ApiError} on any response
 * with a status code not within 200-299 <br/>
 *
 * Errors are automatically logged to the console. Two entries are logged,
 * the error stack trace, and the {@link ApiError} object.
 *
 * For more complicated requests, raw fetch may be used.
 */
class BasicApi {

    constructor() {
        bindClassMethods(this);
    }

    /**
     * Perform an HTTP request
     *
     * @returns {Promise<object>} Returns a Promise that resolves to a response body and rejects on any non-200
     *     response.
     *
     * @throws the response status text
     */
    static httpRequest(
        method: HTTP_METHOD,
        url: string,
        data?: BodyInit,
        headers?: Record<string, string>,
    ): Promise<object> {
        const fetchOptions: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: data,
        };

        return fetch(url, fetchOptions)
            .then(async response => {
                let text = await response.text();
                let data: {message?: string} = text.length ? JSON.parse(text) : text;

                if (!response.ok) {
                    const errorResponse = data as ErrorResponse;
                    const error = new ApiError({
                        message: data.message ?? 'An Error Occurred',
                        messageList: errorResponse.messageList ?? undefined,
                        request: {...fetchOptions, url},
                        response: {
                            headers: response.headers,
                            redirected: response.redirected,
                            status: response.status,
                            statusText: response.statusText,
                            type: response.type,
                            url: response.url,
                            data: data,
                        },
                    });
                    BasicApi.logError(error);
                    throw error;
                }

                return data;
            });
    }

    public static logError(error: ApiError | Response | string): void {
        // print stacktrace or message
        console.error(error);
        // print error object
        if (error as ApiError) {
            error = error as ApiError;
            console.error('-->', {
                message: error.message,
                request: error.request,
                response: error.response,
            });
        }
    }

    static get(url: string): Promise<unknown> {
        return BasicApi.httpRequest(HTTP_METHOD.GET, url);
    }

    static post(url: string, data?: object): Promise<unknown> {
        return BasicApi.httpRequest(HTTP_METHOD.POST, url, JSON.stringify(data));
    }

    static put(url: string, data?: object): Promise<unknown> {
        return BasicApi.httpRequest(HTTP_METHOD.PUT, url, JSON.stringify(data));
    }

    static delete(url: string): Promise<unknown> {
        return BasicApi.httpRequest(HTTP_METHOD.DELETE, url);
    }

    static getWithQuery(baseUrl: String, queryParameters: Record<string, string>): Promise<unknown> {
        const url = `${baseUrl}?${new URLSearchParams(queryParameters)}`;
        return BasicApi.httpRequest(HTTP_METHOD.GET, url);
    }

    static postMultiPartForm(url: string, data: BodyInit) {
        return fetch(
            url,
            {
                method: 'POST',
                body: data,
                headers: DEFAULT_HEADERS,
            },
        )
            .then(response => response.json())
            .then(res => {
                if (res.error) {
                    throw res.error;
                }
                return res;
            });
    }

    static addMultipartFile(formData: FormData, fileFieldName: string, file: File) {
        if (file) {
            formData.append(fileFieldName, file);
        }
    }

    static createMultipartJsonAndFileRequest(
        jsonFieldName: string,
        jsonObject: {fileName: string},
        fileFieldName: string,
        file: File,
    ) {
        const formData = new FormData();
        formData.append(
            jsonFieldName,
            new Blob([JSON.stringify(jsonObject)], {type: 'application/json'}),
            jsonObject.fileName,
        );
        BasicApi.addMultipartFile(formData, fileFieldName, file);
        return formData;
    }

    static login(username: string, password: string): Promise<unknown> {
        return BasicApi.httpRequest(
            HTTP_METHOD.POST,
            LOGIN_ROUTE,
            undefined,
            {
                'credentials': 'include',
                'Authorization': `Basic ${btoa(username + ':' + password)}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        );
    }

    static logout(): Promise<unknown> {
        return BasicApi.post(LOGOUT_ROUTE);
    }

    static refreshSecurityContext(): Promise<unknown> {
        return BasicApi.post(CONTEXT_ROUTE);
    }

}

export {
    BasicApi as default,
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    CONTEXT_ROUTE,
    LOGIN_PATH,
    CSRF_HEADER,
    DEFAULT_HEADERS,
    HTTP_METHOD,
};