import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Button, Label, Message } from 'semantic-ui-react';
import FieldText from 'common/form/fields/FieldText';
import FieldInteger from 'common/form/fields/FieldInteger';
import FieldRichText from 'common/form/fields/FieldRichText';
import FieldPassword from 'common/form/fields/FieldPassword';
import FieldCheckBox from 'common/form/fields/FieldCheckBox';
import FieldCheckList from 'common/form/fields/FieldCheckList';
import FieldToggle from 'common/form/fields/FieldToggle';
import FieldTriBool from 'common/form/fields/FieldTriBool';
import FieldSelect from 'common/form/fields/FieldSelect';
import FieldMultiSelect from 'common/form/fields/FieldMultiSelect';
import FieldFileUpload from 'common/form/fields/FieldFileUpload';
import FieldDate from 'common/form/fields/FieldDate';
import FieldInformation from 'common/form/fields/FieldInformation';
import FieldTextArea from 'common/form/fields/FieldTextArea';
import FieldFeeSchedule from 'common/form/fields/FieldFeeSchedule';
import FieldRedressSchedule from 'common/form/fields/FieldRedressSchedule';
import FieldHelp from 'common/form/FieldHelp';
import FieldAddress from 'common/form/fields/FieldAddress';
import FieldRoutingCondition from 'common/form/fields/FieldRoutingCondition';
import FieldCustomerAttributes from 'common/form/fields/FieldCustomerAttributes';
import FieldDivider from 'common/form/fields/FieldDivider';
import FieldCustomerTagSearch from 'common/form/fields/FieldCustomerTagSearch';
import FieldAdvisorTagSearch from 'common/form/fields/FieldAdvisorTagSearch';

import isEmpty from 'lodash/isEmpty';
import GenericForm from 'external/form/GenericForm';
import RulesGenericForm from 'external/form/RulesGenericForm';

const SemanticUIFormField = (props) => {
    let label = props.label;
    let showLabel = true;
    if (props.type === 'information') {
        showLabel = false;
    }

    if (props.required) {
        label = <>{props.label} (<span style={{color: 'red'}}>*</span>)</>;
    }
    return (
        <Form.Field
            error={!isEmpty(props.error)}
        >
            {showLabel &&
                <label htmlFor={props.propertyName} className="form-label">
                    {label}<FieldHelp title={`${props.label}`} helpText={props.helpText} />
                </label>
            }
            {props.children}
            {props.error &&
                <Label basic color="red" pointing>{props.error}</Label>
            }
        </Form.Field>
    );
};

SemanticUIFormField.propTypes = {
    children: PropTypes.element.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.node.isRequired,
    propertyName: PropTypes.string.isRequired,
    type: PropTypes.string,
};

SemanticUIFormField.defaultProps = {
    error: undefined,
    required: false,
    type: undefined,
};

const SemanticUIForm = ({children}) => {
    return (
        <Container fluid textAlign="left">
            <Form autoComplete="off">
                {children}
            </Form>
        </Container>
    );
};

SemanticUIForm.propTypes = {
    children: PropTypes.node,
};

SemanticUIForm.defaultProps = {
    children: undefined,
};

const ButtonContainer = ({children}) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

ButtonContainer.propTypes = {
    children: PropTypes.node,
};

ButtonContainer.defaultProps = {
    children: undefined,
};

const SEMANTIC_UI_THEME = {
    form: SemanticUIForm,
    formField: SemanticUIFormField,
    formButton: Button,
    formButtonListContainer: ButtonContainer,
};

const REGISTERED_FIELDS = {
    text: FieldText,
    integer: FieldInteger,
    password: FieldPassword,
    checkbox: FieldCheckBox,
    checklist: FieldCheckList,
    toggle: FieldToggle,
    tribool: FieldTriBool,
    select: FieldSelect,
    multiselect: FieldMultiSelect,
    fileupload: FieldFileUpload,
    textarea: FieldRichText,
    date: FieldDate,
    information: FieldInformation,
    plaintextarea: FieldTextArea,
    feeschedule: FieldFeeSchedule,
    redressschedule: FieldRedressSchedule,
    address: FieldAddress,
    customerAttributes: FieldCustomerAttributes,
    routingCondition: FieldRoutingCondition,
    divider: FieldDivider,
    advisortag: FieldAdvisorTagSearch,
    customertag: FieldCustomerTagSearch,
    // subheading: Subheading,
    // hidden: HiddenField,
    // link: FieldText,
    // masktext: FieldMaskedText,
    // number: FieldNumber,
    // datetime: FieldDateTime,
    // datetimezone: FieldDateTimeZone,
    // toggle: FieldToggleButton,
    // imageinput: FieldImageInput,
    // component: FieldComponent,
    // flexible: FieldFlexible,
    // offence: OffencePickerFormControl,
    // domain: FieldDomain,
    // userpicker: FieldUserPicker,
    // map: LocationPicker,
    // togglelist: ToggleListField,
    // label: Empty,
    // recordpermission: RecordPermissionFormControl,
};

const FieldError = ({name, type}) => (
    <Message warning visible>
        Warning! Field not defined: <b>{name} - {type}</b>
    </Message>
);

const SemForm = (props) => {
    return (
        <GenericForm
            {...props}
            theme={SEMANTIC_UI_THEME}
            allowedFields={REGISTERED_FIELDS}
            fieldError={FieldError}
        >
            {props.children}
        </GenericForm>
    );
};

const RulesSemForm = (props) => {
    return (
        <RulesGenericForm
            {...props}
            theme={SEMANTIC_UI_THEME}
            allowedFields={REGISTERED_FIELDS}
            fieldError={FieldError}
        >
            {props.children}
        </RulesGenericForm>
    );
};

export {
    SemanticUIForm as Form,
    SemanticUIFormField as FormField,
    Button as FormButton,
    RulesSemForm,
    SemForm as default,
};
