import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Table, Item } from 'semantic-ui-react'
import bindClassMethods from 'common/util/AutoBind';
import CreateCustomerCorrespondenceModal from 'components/customer/CreateCustomerCorrespondenceModal';
import BasicApi from 'api/BasicApi';
import { ISOStringToFriendlyDate } from 'common/util/DateUtils';
import MediaViewer from 'common/MediaViewer';
import isNil from 'lodash/isNil';
import { withUserContextProp } from 'UserContext';
import Authority, { userHasAnyPermission } from 'common/auth/Authority';
import { DeleteCorrespondenceButton } from 'components/customer/DeleteCorrespondenceButton';
import ShowWithPermission from 'common/auth/ShowWithPermission';

class CustomerCorrespondenceList extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            correspondenceItems: [],
            _isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({_isMounted: true});
        this.getCustomerCorrespondence();
    }

    componentWillUnmount() {
        this.setState({_isMounted: false});
    }

    getCustomerCorrespondence() {
        BasicApi.get(`/api/customers/${this.props.customerId}/correspondence/`)
            .then(this.setCustomerCorrespondence)
            .catch(BasicApi.logError);

    }

    setCustomerCorrespondence(data) {
        if (this.state._isMounted) {
            this.setState({
                correspondenceItems: data,
            });
        }
    }

    hasPreviewDetails(correspondenceItem) {
        return !isNil(correspondenceItem.previewDetails);
    }

    hasAttachment(correspondenceItem) {
        return !isNil(correspondenceItem.attachmentId);
    }

    deleteCorrespondence(correspondenceItem) {
        BasicApi.delete(`/api/customers/${this.props.customerId}/correspondence/${correspondenceItem.correspondenceId}`)
            .then(this.getCustomerCorrespondence)
            .catch(BasicApi.logError);
    }

    render() {
        return (
            <>
                {!this.props.readOnly &&
                    userHasAnyPermission([Authority.CreateCorrespondence], this.props.userContext) &&
                    <Container textAlign="right" fluid>
                        <CreateCustomerCorrespondenceModal
                            customerId={this.props.customerId}
                            update={this.getCustomerCorrespondence}
                        />
                    </Container>
                }
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Direction</Table.HeaderCell>
                            <Table.HeaderCell>Agent</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Attachment</Table.HeaderCell>
                            <ShowWithPermission permission={Authority.ManageCorrespondence}>
                                <Table.HeaderCell textAlign="right" style={{width: '60px'}} />
                            </ShowWithPermission>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.correspondenceItems.map(correspondenceItem => (
                            <Table.Row key={correspondenceItem.correspondenceId}>
                                <Table.Cell>{ISOStringToFriendlyDate(correspondenceItem.date)}</Table.Cell>
                                <Table.Cell>{correspondenceItem.type}</Table.Cell>
                                <Table.Cell>{correspondenceItem.direction}</Table.Cell>
                                <Table.Cell>{correspondenceItem.addedByName}</Table.Cell>
                                <Table.Cell>{correspondenceItem.description}</Table.Cell>
                                <Table.Cell style={{width: '100px'}}>
                                    <Item.Group>
                                        {this.hasPreviewDetails(correspondenceItem) &&
                                            <Item>
                                                <MediaViewer {...correspondenceItem.previewDetails} />
                                            </Item>
                                        }
                                        {this.hasAttachment(correspondenceItem) &&
                                            <Item>
                                                <Button
                                                    compact
                                                    as="a"
                                                    target="_blank"
                                                    download
                                                    href={`/api/customers/${this.props.customerId}/files/${correspondenceItem.attachmentId}/download`}
                                                    icon="download"
                                                />
                                            </Item>
                                        }</Item.Group></Table.Cell>
                                <ShowWithPermission permission={Authority.ManageCorrespondence} >
                                    <Table.Cell>
                                        <DeleteCorrespondenceButton
                                            onDeleteItem={() => this.deleteCorrespondence(correspondenceItem)}
                                            correspondenceItem={correspondenceItem}
                                        />
                                    </Table.Cell>
                                </ShowWithPermission>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </>
        )
    }

}

CustomerCorrespondenceList.propTypes = {
    customerId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
}

CustomerCorrespondenceList.defaultProps = {
    readOnly: false,
}

export default withUserContextProp(CustomerCorrespondenceList);
