import React, { useState, ReactElement } from 'react';
import { Button } from 'semantic-ui-react';
import ConfirmDelete from 'components/common/ConfirmDelete';
import { CorrespondenceItemSummary } from 'components/customer/CorrespondenceItemSummary';
import { ISOStringToFriendlyDate } from 'common/util/DateUtils';

type Props = {
    onDeleteItem: () => void,
    correspondenceItem: CorrespondenceItemSummary,
};

/**
 * Displays a button to delete the correspondence item.
 */
export function DeleteCorrespondenceButton({
    onDeleteItem,
    correspondenceItem,
}: Props): ReactElement | null {
    const [showModal, setShowModal] = useState<boolean>(false);

    function onRequestDeleteSelected() {
        setShowModal(false);
        onDeleteItem();
    }

    return (
        <>
            <ConfirmDelete
                open={showModal}
                onConfirm={onRequestDeleteSelected}
                onCancel={() => setShowModal(false)}
                title="Delete this customer correspondence entry?"
                content={
                    <>
                        <p>
                            <strong>Date</strong><br />
                            {ISOStringToFriendlyDate(correspondenceItem.date)}
                        </p>
                        <p>
                            <strong>Type</strong><br />
                            {correspondenceItem.type}
                        </p>
                        <p>
                            <strong>Direction</strong><br />
                            {correspondenceItem.direction}
                        </p>
                        <p>
                            <strong>Description</strong><br />
                            {correspondenceItem.description}
                        </p>
                    </>
                }
            />
            <Button
                basic
                compact
                size="small"
                icon="trash"
                onClick={() => setShowModal(true)}
            />
        </>
    );
}
