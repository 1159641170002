import { ErrorResponse } from 'api/BasicApi';

type ApiResponse = Pick<Response, 'headers' | 'redirected' | 'status' | 'statusText' | 'type' | 'url'>

interface args {
    message: string;
    messageList: string[] | undefined;
    request: RequestInit & {url: string},
    response: ApiResponse & {data: ErrorResponse},
}

export default class ApiError extends Error {
    public messageList;
    public request;
    public response;

    constructor(args: args) {
        super(args.message);
        this.messageList = args.messageList;
        this.request = args.request;
        this.response = args.response;
    }

}
