import React, { SyntheticEvent } from 'react';
import bindClassMethods from 'common/util/AutoBind';
import {
    Container,
    DropdownProps,
    Form,
    Loader,
    Pagination,
    PaginationProps,
    Segment,
    Select,
    Table,
} from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import 'components/admin/AuditLog.css';
import { ISOStringToFriendlyDateTime } from 'common/util/DateUtils';
import { AuditLogItem, AuditLogResponse } from 'api/AuditTypes';
import { UserResponse } from 'api/UserTypes';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import withAuditLogQueryParams, { WithAuditLogQueryParamsProps } from 'components/admin/withAuditLogQueryParams';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';

const convertAFromDateToTimestamp = (value: Date | Date[] | undefined | null) => {
    if (value === undefined) {
        return;
    }

    if (!(value instanceof Date)) {
        return;
    }
    // set start of day
    value.setHours(0, 0, 0, 0);
    return value.toISOString();
};

const convertAToDateToTimestamp = (value: Date | Date[] | undefined | null) => {
    if (value === undefined) {
        return;
    }

    if (!(value instanceof Date)) {
        return;
    }
    // set end of day
    value.setHours(23, 59, 59, 999);
    return value.toISOString();
};

const parseDate = (inputString?: string) => {
    if (!inputString) {
        return;
    }

    return new Date(inputString);
};

interface UserDropdownItems {
    key: string
    text: string,
    value: string
}

type AuditLogProps = {} & WithAuditLogQueryParamsProps

type AuditLogState = {
    loading: boolean
    paginationState: PaginationProps
    events: AuditLogItem[]
    userDropdownOptions: UserDropdownItems[]
    fromDate?: Date
    toDate?: Date
}

class AuditLog extends React.Component<AuditLogProps, AuditLogState> {

    constructor(props: AuditLogProps) {
        super(props);
        bindClassMethods(this);
        this.state = {
            events: [],
            loading: false,
            paginationState: {
                activePage: 1,
                boundaryRange: 2,
                totalPages: 0,
                firstItem: null,
                lastItem: null,
            },
            userDropdownOptions: [],
            fromDate: undefined,
            toDate: undefined,
        };
    }

    componentDidMount() {
        this.getAuditEvents();
        this.getUserDropdownOptions();
    }

    componentDidUpdate(prevProps: Readonly<AuditLogProps>) {
        if (prevProps.auditLogQuery.queryString !== this.props.auditLogQuery.queryString) {
            this.getAuditEvents();
        }
    }

    getAuditEvents() {
        this.setState({loading: true});
        BasicApi.get(`/api/audits/task${this.props.auditLogQuery.queryString}`)
            .then((res) => this.setEvents(res as AuditLogResponse))
            .finally(() => this.setState({loading: false}));
    }

    setEvents(data: AuditLogResponse) {
        this.setPagination(data.current, data.total);
        this.setState({events: data.entries});
    }

    setPagination(current: number, total: number) {
        this.setState(
            {
                paginationState: {
                    ...this.state.paginationState,
                    activePage: current,
                    totalPages: total,
                },
            },
        );
    }

    getUserDropdownOptions() {
        BasicApi.get(`/api/users`)
            .then((res) => this.setUserDropdownOptions(res as UserResponse));
    }

    setUserDropdownOptions(data: UserResponse) {
        const dropdownItems = data.map(user => {
            return {
                key: user.id,
                text: user.name,
                value: user.id,
            };
        });
        dropdownItems.push({
            key: '<SYSTEM>',
            text: '<SYSTEM>',
            value: '<SYSTEM>',
        });
        this.setState({userDropdownOptions: dropdownItems});
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Audit Log', content: 'Audit Log'},
        ];
    }

    handlePaginationChange(_event: SyntheticEvent, {activePage}: PaginationProps) {
        if (activePage && !isNaN(+activePage)) {
            this.props.updateAuditLogQuery('page', +activePage);
        }
    }

    updateFromDate({value}: SemanticDatepickerProps) {
        if ((value instanceof Date) || value === undefined) {
            this.setState({fromDate: value});
        }

        if (value === null) {
            this.setState({fromDate: undefined});
        }
        this.props.updateAuditLogQuery('fromDate', convertAFromDateToTimestamp(value));
    }

    updateToDate({value}: SemanticDatepickerProps) {
        if ((value instanceof Date) || value === undefined) {
            this.setState({toDate: value});
        }

        if (value === null) {
            this.setState({toDate: undefined});
        }
        this.props.updateAuditLogQuery('toDate', convertAToDateToTimestamp(value));
    }

    updateUserQueryParam({value}: DropdownProps) {
        if (typeof value !== 'string') {
            return;
        }

        this.props.updateAuditLogQuery('user', value);
    }

    updateSearchQueryParameter(query?: string) {
        this.props.updateAuditLogQuery('searchQuery', query);
    }

    onClearFilters() {
        this.props.onResetFilters();
    }

    render() {
        return (
            <>
                <PageHeader
                    text="Audit Log"
                    icon={<LoaderIcon icon="tasks" onClick={this.getAuditEvents} loading={this.state.loading} />}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                <Segment attached={'bottom'}>
                    <Loader active={this.state.loading} />
                    <Segment attached="top">
                        <Form>
                            <Form.Group>
                                <Form.Field>
                                    <label>User</label>
                                    <Select
                                        clearable={true}
                                        value={this.props.auditLogQuery.user ? this.props.auditLogQuery.user : ''}
                                        options={this.state.userDropdownOptions}
                                        onChange={(_event, data) => this.updateUserQueryParam(data)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>From</label>
                                    <SemanticDatepicker
                                        value={parseDate(this.props.auditLogQuery.fromDate)}
                                        maxDate={parseDate(this.props.auditLogQuery.toDate)}
                                        format="DD-MM-YYYY"
                                        onChange={(_event, data) => this.updateFromDate(data)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>To</label>
                                    <SemanticDatepicker
                                        value={parseDate(this.props.auditLogQuery.toDate)}
                                        minDate={parseDate(this.props.auditLogQuery.fromDate)}
                                        onChange={(_event, data) => this.updateToDate(data)}
                                        format="DD-MM-YYYY"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Search</label>
                                    <Form.Input
                                        value={this.props.auditLogQuery.searchQuery
                                            ? this.props.auditLogQuery.searchQuery : ''}
                                        onChange={(_event, {value}) => this.updateSearchQueryParameter(value)}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>&nbsp;</label>
                                    <Form.Button content="Clear Filters" onClick={this.onClearFilters} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Segment>
                    <Table attached="bottom" className="table audit-log" compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="date">Time</Table.HeaderCell>
                                <Table.HeaderCell className="source">Source</Table.HeaderCell>
                                <Table.HeaderCell className="user">User</Table.HeaderCell>
                                <Table.HeaderCell className="description">Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.events.map(event => (
                                <Table.Row key={event.id}>
                                    <Table.Cell>{ISOStringToFriendlyDateTime(event.time)}</Table.Cell>
                                    <Table.Cell>{event.source}</Table.Cell>
                                    <Table.Cell>{event.username}</Table.Cell>
                                    <Table.Cell>{event.description}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
                <Container textAlign="center">
                    <Pagination onPageChange={this.handlePaginationChange} {...this.state.paginationState} />
                </Container>
            </>
        );
    }
}

export default withAuditLogQueryParams(AuditLog);