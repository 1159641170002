import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Image, Modal, Icon, Popup, Container } from 'semantic-ui-react';
import MediaPreview from 'common/media/MediaPreview';
import BasicApi from 'api/BasicApi';
import 'common/mediaViewer.scss';
import { getMimeType, lookupFileType } from 'common/media/FileTypes';

const PREVIEW_STATUS = {
    NotStarted: 'NotStarted',
    Processing: 'Processing',
    Complete: 'Complete',
    Failed: 'Failed',
};

const DEFAULT_RECHECK_INTERVAL = 5000;

class MediaViewer extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            showViewer: false,
            previewStatus: this.props.previewStatus,
            mimeType: this.props.mimeType,
            size: undefined,
        };
        this.recheckId = null;
    }

    delayedCheckForCompletion() {
        if (this.props.statusUrl) {
            this.recheckId = setTimeout(this.checkForCompletion, this.props.recheckInterval);
        }
    }

    componentDidMount() {
        if (this.props.previewStatus === PREVIEW_STATUS.Processing) {
            this.delayedCheckForCompletion();
        }
    }

    componentWillUnmount() {
        if (this.recheckId) {
            clearTimeout(this.recheckId);
        }
    }

    checkForCompletion() {
        BasicApi.get(this.props.statusUrl)
            .then(this.processStatus);
    }

    processStatus(data) {
        this.recheckId = null;
        this.setState({
            previewStatus: data.previewStatus,
            mimeType: data.mimeType,
        });
        if (data.previewStatus === PREVIEW_STATUS.Processing) {
            this.delayedCheckForCompletion();
        }
    }

    showViewer() {
        this.setState({showViewer: true})
    }

    hideViewer() {
        this.setState({showViewer: false})
    }

    enableFullScreen() {
        this.setState({size: 'fullscreen'})
    }

    getFileIcon() {
        const fileType = lookupFileType(getMimeType(this.props.fileName));
        return (<Icon
            name={fileType.icon} size="large"
            onClick={this.showViewer}
            className="hover-select hover-grow media-viewer"
        />)
    }

    getDefaultFileIcon() {
        if (this.props.showThumbnail) {
            return <Icon size='big' name='file outline' disabled />
        }

        return this.getFileIcon();
    }

    render() {
        if (this.state.previewStatus === PREVIEW_STATUS.Processing && this.props.showThumbnail) {
            return (
                <Popup
                    position="right center"
                    content={"Generating preview"}
                    trigger={
                        <Icon.Group size='large'>
                            <Icon size='big' name='file outline' color="grey" />
                            <Icon name='cog' loading />
                        </Icon.Group>
                    }
                />
            );
        }

        if (this.state.mimeType === null) {
            return (
                <Popup
                    position="right center"
                    content={"No preview available"}
                    trigger={this.getDefaultFileIcon()}
                />
            );
        }

        // TODO: Enable full screen preview
        // <Icon name="expand arrows alternate" className="hover-select" onClick={this.enableFullScreen} />
        return (
            <React.Fragment>
                {this.props.showThumbnail && <Image
                    src={this.props.thumbUrl}
                    size="tiny"
                    onClick={this.showViewer}
                    className="hover-select media-viewer hover-grow"
                />}
                {!this.props.showThumbnail && this.getFileIcon()}
                <Modal
                    size={this.state.size}
                    closeIcon
                    centered={false}
                    onClose={this.hideViewer}
                    onOpen={this.showViewer}
                    open={this.state.showViewer}
                    className="media-viewer"
                >
                    <Modal.Header>
                        {this.props.fileName}
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Container textAlign="center" fluid>
                            <MediaPreview mimeType={this.state.mimeType} url={this.props.viewUrl} />
                        </Container>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

MediaViewer.propTypes = {
    previewStatus: PropTypes.oneOf(Object.values(PREVIEW_STATUS)).isRequired,
    fileName: PropTypes.string.isRequired,
    statusUrl: PropTypes.string,
    thumbUrl: PropTypes.string.isRequired,
    viewUrl: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
    recheckInterval: PropTypes.number,
    showThumbnail: PropTypes.bool,
};

MediaViewer.defaultProps = {
    statusUrl: null,
    mimeType: null,
    recheckInterval: DEFAULT_RECHECK_INTERVAL,
    showThumbnail: true,
};

export default MediaViewer;