import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react'
import bindClassMethods from 'common/util/AutoBind';
import BasicApi from 'api/BasicApi';

import QRCode from 'qrcode.react';

/**
 * A component that renders the unique QRCode for a user
 */
class UserQRCode extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            loading: true,
            value: null,
        };
    }

    componentDidMount() {
        this.getQRCode();
    }

    getQRCode() {
        this.setState({loading: true});
        BasicApi.get(`/api/users/${this.props.userId}/qrcode`)
            .then(this.setQRCode)
            .finally(() => this.setState({loading: false}));
    }

    setQRCode(data) {
        if (data.id === this.props.userId) {
            this.setState({
                value: data.value,
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }
        return (
            <div id="qr">
                <p>
                    Scan this barcode using your favourite 2-factor verification application
                </p>
                <QRCode value={this.state.value} size={256}/>
            </div>
        );
    }
}

UserQRCode.propTypes = {
    userId: PropTypes.string.isRequired,
}

export default UserQRCode;