import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Container, Divider, Header, Icon } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import PageHeader from 'components/common/PageHeader';

class DatabaseManagementScreen extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {};
    }

    onReindexRequested() {
        return BasicApi.get('/api/reindex');
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Database Management', content: 'Database Management'},
        ];
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader text="Database Management" icon="database" breadcrumbs={this.getBreadcrumbs()} />
                <Divider hidden />
                <Container textAlign="left">
                    <Header as="h3">
                        <Icon name="sync" />
                        <Header.Content>
                            Reindex data
                            <Header.Subheader>Use the following to reindex searchable data for this instance</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Button onClick={this.onReindexRequested}>
                        Reindex Data
                    </Button>

                </Container>
            </React.Fragment>
        );
    }
}

DatabaseManagementScreen.propTypes = {
    children: PropTypes.node,
};

DatabaseManagementScreen.defaultProps = {
    children: undefined,
};

export default DatabaseManagementScreen;