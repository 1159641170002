import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';

import { Container, Divider, Grid, Header, Icon, Loader, Menu, Ref, Sticky } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import CustomerDetails from 'components/customer/CustomerDetails';
import CustomerCases from 'components/customer/CustomerCases';
import { withHeaderAndBreadcrumbs, withHeaderBreadcrumbsAndAlerts } from 'components/common/PageHeader';
import StagingViewTabs from 'components/customer/StagingViewTabs';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import ConfirmCustomerDelete from 'components/customer/ConfirmCustomerDelete';
import { cloneDeep } from 'lodash';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import withProgramme from 'components/programme/ProgrammeProps';

const SideBarItems = [
    {name: 'details', icon: 'user', text: 'Customer Details'},
    {name: 'cases', icon: 'briefcase', text: 'Customer Cases'},
    {name: 'records', icon: 'archive', text: 'Customer Records'},
];

const SideBarMenuItem = ({name, active, icon, text, onItemClicked}) => {
    return (
        <Menu.Item
            name={name}
            active={active}
            onClick={onItemClicked}
        >
            <Container fluid>
                <Icon name={icon} /> {text}
            </Container>
        </Menu.Item>
    );
};

const CustomerSideBar = ({customerData, activeItem, onItemClicked}) => {

    return (
        <Menu vertical secondary fluid>
            <Menu.Header>
                <Container fluid>
                    <Header size="small">
                        <Icon name="user outline" circular />
                        <Header.Content>
                            {customerData.name} {customerData.deleted && <>(Deleted)</>}
                            <Header.Subheader>
                                Customer Number: {customerData.referenceNumber}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Container>
                <Divider />
            </Menu.Header>
            {SideBarItems.map((item) => {
                return (
                    <SideBarMenuItem
                        key={item.name}
                        name={item.name}
                        active={activeItem === item.name}
                        icon={item.icon}
                        text={item.text}
                        onItemClicked={() => onItemClicked(item.name)}
                    />
                );
            })}
        </Menu>
    );
};

const CustomerDetailsSection = withHeaderBreadcrumbsAndAlerts(CustomerDetails);
const CustomerCasesSection = withHeaderBreadcrumbsAndAlerts(CustomerCases);
const CustomerRecordsSection = withHeaderAndBreadcrumbs(StagingViewTabs);

/**
 * The class implements a top level customer page component
 */
class CustomerPage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            customerData: undefined,
            activeItem: props.activeItem,
            deletingCustomer: false,
            alerts: [],
        };

        this.contextRef = createRef();
    }

    componentDidMount() {
        this.getCustomerDetails(this.props.customerId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeItem !== prevProps.activeItem) {
            this.setState({activeItem: this.props.activeItem, alerts: []});
        }
    }

    getCustomerDetails() {
        this.setState({loading: true});
        BasicApi.get(`/api/customers/${this.props.customerId}`)
            .then(this.setCustomer)
            .finally(() => this.setState({loading: false}));
    }

    setCustomer(data) {
        this.setState({
            customerData: data,
        });
        this.clearErrors();
    }

    clearErrors() {
        this.setState({alerts: []});
    }

    onError(title, error) {
        const alerts = cloneDeep(this.state.alerts);
        alerts.push({
            level: "error",
            heading: title,
            content: error.message,
        });
        this.setState({alerts: alerts});
    }

    handleItemClick(item) {
        this.setState({activeItem: item});
        this.props.history.push(`${getCustomerRoute(this.props.programme.id, this.props.customerId)}/${item}`);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Customers', content: 'Customers'},
            {key: 'Customer', content: `${this.state.customerData.name}`},
        ];
    }

    onDeleteCustomer() {
        this.closeDeleteDialog();
        BasicApi.delete(`/api/customers/${this.props.customerId}`)
            .then(this.getCustomerDetails)
            .catch((e) => this.onError("There was an error deleting the customer", e));
    }

    closeDeleteDialog() {
        this.setState({deletingCustomer: false});
    }

    setAlerts(alerts) {
        this.setState({alerts: alerts});
    }

    render() {
        if (this.state.loading || !this.state.customerData) {
            return <Loader active />;
        }
        return (
            <>
                <ConfirmCustomerDelete
                    open={this.state.deletingCustomer}
                    onConfirm={this.onDeleteCustomer}
                    onCancel={this.closeDeleteDialog}
                />
                <Ref innerRef={this.contextRef}>
                    <Grid stretched padded="vertically">
                        <Grid.Column tablet={3} widescreen={2}>
                            <Sticky context={this.contextRef} offset={70}>
                                <CustomerSideBar
                                    customerData={this.state.customerData}
                                    activeItem={this.state.activeItem}
                                    onItemClicked={this.handleItemClick}
                                />
                            </Sticky>
                        </Grid.Column>
                        <Grid.Column tablet={13} widescreen={13}>
                            {this.state.activeItem === 'details' &&
                                <CustomerDetailsSection
                                    text={'Customer Details'}
                                    icon={'user'}
                                    customerId={this.props.customerId}
                                    onError={(e) => this.onError("Operation failed", e)}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                    onDelete={() => this.setState({deletingCustomer: true})}
                                    onUpdate={this.getCustomerDetails}
                                    alerts={this.state.alerts}
                                />
                            }
                            {this.state.activeItem === 'cases' &&
                                <CustomerCasesSection
                                    text={'Customer Cases'}
                                    icon={'briefcase'}
                                    customerId={this.props.customerId}
                                    readOnly={this.state.customerData.deleted}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                    alerts={this.state.alerts}
                                    setAlerts={this.setAlerts}
                                />
                            }
                            {this.state.activeItem === 'records' &&
                                <CustomerRecordsSection
                                    text={'Customer Records'}
                                    icon={'archive'}
                                    customerId={this.props.customerId}
                                    readOnly={this.state.customerData.deleted}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                />
                            }
                        </Grid.Column>
                    </Grid>
                </Ref>
            </>
        );
    }
}

CustomerPage.propTypes = {
    customerId: PropTypes.string.isRequired,
    activeItem: PropTypes.string,
};

CustomerPage.defaultProps = {
    activeItem: SideBarItems[0].name,
};

export default withProgramme(CustomerPage);