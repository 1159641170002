import bindClassMethods from 'common/util/AutoBind';
import React, { ComponentType, FunctionComponent } from 'react';
import BasicApi from 'api/BasicApi';
import { Icon } from 'semantic-ui-react';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';
import { ProgrammeOfWork, ProgrammeResponse } from 'components/programme/ProgrammeTypes';

const DEFAULT_PROGRAMMES: ConfigurationData = {
    programmes: {},
    allowCrossProgrammeSearch: false,
    reloadProgrammes: async () => {},
};

const ConfigurationContext = React.createContext<ConfigurationData>(DEFAULT_PROGRAMMES);

type Props = {
    children?: React.ReactElement,
};

type State = {
    configuration: ConfigurationData,
    loading: boolean,
};

export default class ConfigurationDataContext extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            configuration: DEFAULT_PROGRAMMES,
            loading: true,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    loadAll() {
        this.setState({loading: true});
        Promise.all([
            this.getProgrammesPromise(),
            this.getSearchConfigPromise(),
        ])
        .finally(() => this.setState({loading: false}));
    }

    getProgrammesPromise() {
        return BasicApi
            .get('/api/programmes')
            .then((programmes: unknown) => this.setProgrammes(programmes as ProgrammeResponse[]));
    }

    getSearchConfigPromise() {
        return BasicApi
            .get('/noauth/config/search')
            .then((programmes: unknown) => this.setSearchConfig(programmes as ProgrammeResponse[]));
    }

    setSearchConfig(data: object) {
        this.setState((state: State) => {
            return {
                configuration: {
                    ...state.configuration,
                    ...data,
                },
            };
        });
    }

    setProgrammes(programmes: ProgrammeResponse[]) {
        const programmeMap: Record<string, ProgrammeOfWork> = {};
        programmes.forEach((p: ProgrammeResponse) => {
                programmeMap[`${p.programmeId}`] = {
                    id: `${p.programmeId}`,
                    name: p.name,
                    meta: 'Remediation Program',
                    uri: getProgrammeRoute(p.programmeId),
                    restricted: p.restricted,
                    archived: p.archived,
                    icon: <Icon name={p.icon} circular />,
                };
            },
        );
        this.setState((state: State) => {
            return {
                configuration: {
                    ...state.configuration,
                    programmes: programmeMap,
                },
            };
        });
    }

    render() {
        return (
            <ConfigurationContext.Provider
                value={
                    {...this.state.configuration, reloadProgrammes: this.getProgrammesPromise}}
            >
                {!this.state.loading && this.props.children}
            </ConfigurationContext.Provider>
        );
    }
}

/**
 * Application configuration properties.
 */
export type ConfigurationData = {
    programmes: Record<string, ProgrammeOfWork>,
    allowCrossProgrammeSearch: boolean,
    reloadProgrammes: () => void,
};

/**
 * Wraps a component with the configuration context passed in.
 *
 * @param {React.ComponentType<P>} Component Component to pass configuration context to.
 * @return {React.FunctionComponent<P & ConfigurationDataProps>} Component with the configuration context.
 * @deprecated Use hooks instead or add contextType to a class component.
 */
export function withConfiguration<P>(Component: ComponentType<P>): FunctionComponent<P> {
    return (props) => (
        <ConfigurationContext.Consumer>
            {(configurationData) => <Component configurationData={configurationData} {...props as P} />}
        </ConfigurationContext.Consumer>
    );
}

export type ConfigurationDataProps = {
    configurationData: ConfigurationData,
};
