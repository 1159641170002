import { Operator } from 'json-rules-engine';
import { isNilOrEmpty } from 'external/form/Validators';

function stringValidator(factValue: string): boolean {
    return !isNilOrEmpty(factValue);
}

function stringContains(baseString: string, containString: string): boolean {
    if (!baseString || !containString) {
        return false;
    }

    if (!baseString.length || !containString.length) {
        return false;
    }

    return containString.indexOf(baseString) > -1;
}

const stringContainsOperator = new Operator(
    'stringContains',
    (factValue: string, jsonValue: string) => {
        return stringContains(jsonValue, factValue);
    },
    stringValidator,
);

const allOperators: Operator[] = [
    stringContainsOperator,
];

export {
    allOperators as default,
    stringContainsOperator,
};