import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import GenericForm from 'common/form/SemanticUIForm';
import { Loader } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';

/**
 * This class implements a CustomerForm component to be used for
 * adding and editing customer details.
 */
class CustomerForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            formData: {},
            loading: true,
            saving: false,
            schema: {},
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        Promise
            .all([
                this.loadCustomer(this.props.customerId),
                this.loadSchema(),
            ])
            .then(this.setDefaultValues)
            .catch(this.onError)
            .finally(() => this.setState({loading: false}));
    }

    loadCustomer(customerId) {
        if (!customerId) {
            return;
        }
        return BasicApi.get(`/api/customers/${customerId}`)
            .then(this.setCustomer)
    }

    loadSchema() {
        return BasicApi.get('/api/customers/schema')
            .then(this.setSchema);
    }

    setCustomer(data) {
        const formData = {
            ...data,
            ...data.customAttributes,
        };
        delete formData.customAttributes;
        this.setState({formData});
    }

    setSchema(data) {
        this.setState({
            schema: data.customerEditSchema,
        });
    }

    setDefaultValues() {
        let newFormData = {...this.state.formData}
        for (const schemaItem of this.state.schema) {
            if (schemaItem.type === "toggle" && !newFormData[schemaItem.customerKey]) {
                newFormData = {...newFormData, [schemaItem.customerKey]: false}
            }
        }
        this.setState({formData: newFormData});
    }

    onError(error) {
        this.props.onError(error.message);
    }

    onCustomerChanged(customerId) {
        this.setState({
            saving: false,
        }, () => this.props.onCustomerChanged(customerId));
    }

    onSubmit(formData) {
        this.setState({
            saving: true,
        });
        if (this.props.customerId) {
            return BasicApi.put(`/api/customers/${this.props.customerId}`, this.getRequestBody())
                .catch(this.onError)
        }
        return BasicApi.post('/api/customers', this.getRequestBody())
            .catch(this.onError)
    }

    onSubmitSuccess(customerId) {
        this.setState({formData: {}});
        this.onCustomerChanged(customerId);
    }

    onFormDataChanged(formData) {
        this.setState({formData})
    }

    onCancel() {
        this.props.onCancel();
    }

    getFormSchema() {
        const schema = {
            fields: {},
            fieldOrder: [],
        }
        for (const field of this.state.schema) {
            schema.fieldOrder.push(field.customerKey);
            schema.fields[field.customerKey] = {
                type: field.type,
                label: field.label,
                required: field.required,
                placeHolder: field.placeHolder,
            }
        }
        return schema;
    }

    getRequestBody() {
        const body = {customAttributes: {}}
        for (const field of this.state.schema) {
            if (field.custom && this.state.formData[field.customerKey] !== undefined) {
                body.customAttributes[field.customerKey] = this.state.formData[field.customerKey];
            } else {
                body[field.customerKey] = this.state.formData[field.customerKey];
            }
        }
        if (this.props.customerId) {
            body.customerId = this.props.customerId;
        }
        return body;
    }

    render() {
        if(this.state.loading){
            return <Loader active/>;
        }
        return (
            <>
                <GenericForm
                    schema={this.getFormSchema()}
                    formData={this.state.formData}
                    onFormDataChanged={this.onFormDataChanged}
                    navigationPrompt={false}
                    showButtons
                    styleClass="left-align-fields"
                    onSubmit={this.onSubmit}
                    onSubmitSuccess={this.onSubmitSuccess}
                    onSubmitError={this.onError}
                    onCancel={this.onCancel}
                    submitButtonLabel='Save'
                    submitButtonDisabled={this.state.submitting}
                />
            </>
        );
    }
}

CustomerForm.propTypes = {
    onCustomerChanged: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    customerId: PropTypes.string,
}

CustomerForm.defaultProps = {
    customerId: undefined,
}

export default CustomerForm;
