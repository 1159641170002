import React, { useEffect, useState } from 'react';
import GenericForm, { GenericFormFieldSchema, GenericFormSchema, FormData } from 'external/form/GenericForm';
import { Engine, RuleProperties, Fact, EngineOptions, Operator, Rule } from 'json-rules-engine';
import { cloneDeep } from 'lodash';
import customOperators from 'external/form/CustomRuleOperators';

const ENGINE_OPTIONS: EngineOptions = {
    allowUndefinedFacts: true,
};

interface Props {
    schema: GenericFormSchema,
    formData: {}
}

const RulesGenericForm = (props: Props) => {
    const [schema, setSchema] = useState(props.schema);
    const [formData, setFormData] = useState(props.formData);
    const [rulesEngine, setRulesEngine] = useState<Engine | null>(null);

    useEffect(() => {
        setEngine();

        function setEngine() {
            const ruleFields = getRuleFields(props.schema);
            const rulesEngine = new Engine(getRules(ruleFields), ENGINE_OPTIONS);

            customOperators.forEach((operator: Operator) => {
                rulesEngine.addOperator(operator);
            });

            setRulesEngine(rulesEngine);
        }

        function getRuleFields(schema: GenericFormSchema): Record<string, GenericFormFieldSchema> {
            return Object.fromEntries(
                Object.entries(schema.fields).filter(
                    ([, value]) => !!value.requiredWhen,
                ),
            );
        }

        function getRules(field: Record<string, GenericFormFieldSchema>): RuleProperties[] {
            return Object.entries(field).map(([fieldName, value]) => {
                    return new Rule({
                        ...value.requiredWhen!,
                        onSuccess: () => setMandatory(fieldName, true),
                        onFailure: () => setMandatory(fieldName, false),
                    });
                },
            );
        }

        function setMandatory(fieldName: string, isRequired: boolean): void {
            let mandatoryField = cloneDeep(props.schema.fields[fieldName]);
            mandatoryField.required = isRequired;

            setSchema(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [fieldName]: mandatoryField,
                },
            }));
        }
    }, [props.schema]);

    useEffect(() => {
        runEngine();

        function runEngine() {
            if (!rulesEngine) {
                return;
            }

            const facts = Object.entries(formData).map(
                ([name, value]) => new Fact(name, value),
            );

            rulesEngine!.run(facts);
        }
    }, [rulesEngine, formData]);

    const onInputChanged = (name: string, value: any): void => {
        const newFormData: FormData = cloneDeep(formData);
        newFormData[name] = value;

        setFormData(newFormData);
    };

    return (
        <GenericForm
            {...props}
            schema={schema}
            formData={formData}
            onInputFieldChanged={onInputChanged}
        />
    );

};

export default RulesGenericForm;



