import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Label, Loader } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import BasicApi from 'api/BasicApi';
import { withUserContextProp } from 'UserContext';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import UserGroupTable from 'components/admin/UserGroupTable';
import { cloneDeep } from 'lodash';

class UserList extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            users: [],
            groups: [],
            newUsers: [],
            editing: false,
            loading: false,
            error: "",
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        Promise.all([
                this.getUsers(),
                this.getGroups(),
            ])
            .finally(() => this.setState({loading: false}));
    }

    getUsers() {
        BasicApi.get(`/api/users`)
            .then(this.setUsers);
    }

    getGroups() {
        BasicApi.get(`/noauth/config`)
            .then(this.setGroups);
    }

    setUsers(data) {
        this.setState({users: data});
    }

    setGroups(data) {
        this.setState({groups: data.groups});
    }

    isCurrentUser(user) {
        return user.id === this.props.userContext.username;
    }

    getLabel(groupName) {
        return <Label color="grey" key={groupName} basic>{groupName}</Label>;
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'Users & User Groups', content: 'Users & User Groups'},
        ];
    }

    onEditUserGroups() {
        this.setState({
            editing: true,
            newUsers: cloneDeep(this.state.users),
            error: "",
        });
    }

    onSaveUserGroups() {
        this.setState({loading: true, editing: false});
        BasicApi.put(`/api/users/groups`, this.getUserGroupRequestBody())
            .then(this.loadData)
            .catch(this.onError)
            .finally(() => this.setState({loading: false}));
    }

    onError(error) {
        this.setState({error: error.message || error});
    }

    updateUser(userIndex, newUser) {
        const newUsers = cloneDeep(this.state.newUsers);
        newUsers[userIndex] = newUser;
        this.setState({newUsers: newUsers});
    }

    getUserGroupRequestBody() {
        const users = this.state.newUsers.map(user => {
            return {
                userId: user.id,
                groupIds: user.groups.map(group => group.id),
            };
        });

        return {users: users};
    }

    getHeaderButtons() {
        if (this.state.editing) {
            return (<>
                <Button
                    compact
                    size="tiny"
                    color="blue"
                    onClick={this.onSaveUserGroups}
                    content="Save"
                    icon="save"
                />
                <Button
                    compact
                    size="tiny"
                    color="blue"
                    onClick={() => this.setState({editing: false})}
                    content="Cancel"
                    icon="times"
                />
            </>);
        }

        return (<>
            <Button
                as={Link}
                to={`/admin/users/new`}
                compact
                size="tiny"
                color="blue"
                content="Add User"
                icon="plus"
            />
            <Button
                compact
                size="tiny"
                color="blue"
                onClick={this.onEditUserGroups}
                content="Edit Users"
                icon="edit"
            />
        </>);
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        return (
            <>
                <PageHeader
                    text="Users & User Groups"
                    icon={<LoaderIcon icon="users" onClick={this.getUsers} loading={this.state.loading} />}
                    breadcrumbs={this.getBreadcrumbs()}
                    headerRightComponent={this.getHeaderButtons()}
                />
                <UserGroupTable
                    users={this.state.editing ? this.state.newUsers : this.state.users}
                    groups={this.state.groups}
                    editing={this.state.editing}
                    updateUser={this.updateUser}
                    error={this.state.error}
                />
            </>
        );
    }
}

UserList.propTypes = {
    userContext: PropTypes.shape({
        user: PropTypes.shape({
            permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
    }).isRequired,
    children: PropTypes.node,
};

UserList.defaultProps = {
    children: undefined,
};

export default withUserContextProp(UserList);