import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { Accordion, Header, Icon, Loader, Segment, Table } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import BasicApi from 'api/BasicApi';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import { getAdminRoute } from 'components/admin/AdminRouter';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';
import withProgramme from 'components/programme/ProgrammeProps';

class FieldList extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            workflows: [], loading: true, activeScreenId: null,
        };
    }

    componentDidMount() {
        this.getWorkflow();
    }

    getWorkflow() {
        this.setState({loading: true});
        BasicApi.get(`/api/workflow/programme/${getProgrammeIdFromCurrentPath()}`)
            .then(this.setWorkflow)
            .finally(() => this.setState({loading: false}));
    }

    setWorkflow(data) {
        this.setState({workflow: data});
    }

    getBreadcrumbs() {
        return [{key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'ProgramAdministration', content: 'Program Administration'}, {key: 'Fields', content: 'Fields'}];
    }

    setActiveAccordion(screenId) {
        if (this.state.activeScreenId === screenId) {
            this.setState({activeScreenId: null});
            return;
        }
        this.setState({activeScreenId: screenId});
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        return (<>
            <PageHeader
                text="Fields"
                icon={<LoaderIcon icon="language" onClick={this.getFields} loading={this.state.loading} />}
                breadcrumbs={this.getBreadcrumbs()}
            />
            <div style={{maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden'}}>
                <Accordion exclusive fluid>
                    {this.state.workflow.screens.map(screen => {
                        return (<React.Fragment key={screen.screenId}>
                            <Accordion.Title
                                active={this.state.activeScreenId === screen.screenId}
                                onClick={() => this.setActiveAccordion(screen.screenId)}
                                as={Segment}
                                attached={true}
                            >
                                <Header as="h4">
                                    <Icon name="dropdown" />
                                    {screen.name}
                                </Header>
                            </Accordion.Title>
                            {this.state.activeScreenId === screen.screenId && <Segment attached={true}>

                                <Table
                                    attached={'bottom'}
                                    style={{minHeight: '70px'}}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Field Name</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Field Id</Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {screen.fields.map(field => (<Table.Row key={field.fieldId}>
                                            <Table.Cell>{field.name}</Table.Cell>
                                            <Table.Cell>{field.textPrompt}</Table.Cell>
                                            <Table.Cell>{field.fieldId}</Table.Cell>
                                            <Table.Cell>
                                                <Link
                                                    to={`${getAdminRoute(getProgrammeIdFromCurrentPath())}/fields/${field.workflowId}/${field.fieldId}`}
                                                    className="nowrap"
                                                >Edit &raquo;</Link>
                                            </Table.Cell>
                                        </Table.Row>))}
                                    </Table.Body>
                                </Table>
                            </Segment>}
                        </React.Fragment>)
                    })}

                </Accordion>
            </div>
        </>);
    }
}

FieldList.propTypes = {};

FieldList.defaultProps = {};

export default withProgramme(FieldList);