import React, { ComponentType, createContext } from 'react';
import bindClassMethods from './common/util/AutoBind';
import { withRouter } from 'react-router-dom';
import { Loader } from "semantic-ui-react";
import isEqual from 'lodash/isEqual';
import { isNil } from 'lodash';
import BasicApi from 'api/BasicApi';

const UserContext = createContext<UserContextData>({
    user: undefined,
    isLoggedIn: () => false,
    logout: () => {},
    hasPermissions: () => false,
    setContext: () => {},
});

type UserDetails = {
    userId: string,
    username: string,
    permissions: string[],
}

type UserApiResponse = {
    userId: string,
    userName: string,
    permissions: string[],
}

export type UserContextData = {
    user: UserDetails | undefined,
    isLoggedIn: () => boolean,
    logout: () => void,
    hasPermissions: (...permissions: string[]) => boolean,
    setContext: (user: UserApiResponse) => void,
}

type Props = {}

type State = {
    loading: boolean,
    user: UserDetails | undefined,
    retryCount: number,
}

class UserContextProvider extends React.Component<Props, State> {
    private intervalId: NodeJS.Timer | undefined;

    constructor(props: Props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            user: undefined,
            loading: true,
            retryCount: 0,
        };
    }

    componentDidMount() {
        this.getCurrentContext();
        this.intervalId = setInterval(this.getCurrentContext, 10000);
    }

    shouldComponentUpdate(_nextProps: Props, nextState: State) {
        return !isEqual(this.state, nextState);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    setContext(response: UserApiResponse) {
        this.setState({
            user: {
                userId: response.userId,
                username: response.userName,
                permissions: response.permissions,
            },
            retryCount: 0,
        });
    }

    getCurrentContext = () => {
        if (!this.state.loading && !this.isLoggedIn()) {
            return;
        }

        BasicApi.refreshSecurityContext()
            .then(response => {
                if (response) {
                    this.setContext(response as UserApiResponse);
                }
            })
            .catch(() => {
                if (this.state.retryCount === 3) {
                    console.warn('Retry count exceeded. Logging user out');
                    this.setState({
                        retryCount: 0,
                        user: undefined,
                    });
                } else if (this.isLoggedIn()) {
                    this.setState({
                        retryCount: this.state.retryCount + 1,
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });

    };

    logout() {
        this.setState({
            user: undefined,
        });
    }

    isLoggedIn() {
        return !isNil(this.state.user);
    }

    hasPermissions(...permissions: string[]) {
        const userPermissions = this.state.user?.permissions || [];
        return permissions.some(p => userPermissions.includes(p));
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        return (
            <UserContext.Provider
                value={{
                    ...this.state,
                    isLoggedIn: this.isLoggedIn,
                    logout: this.logout,
                    hasPermissions: this.hasPermissions,
                    setContext: this.setContext,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

const UserContextConsumer = UserContext.Consumer;

function withUserContextProp<P>(Component: ComponentType<P>) {
    return (props: any) => (
        <UserContextConsumer>
            {value => (
                <Component
                    userContext={value}
                    {...props}
                />
            )}
        </UserContextConsumer>
    );
}

const SomeComponent = withRouter(props => <UserContextProvider {...props} />);

export {
    SomeComponent as default,
    UserContextProvider,
    withUserContextProp,
    UserContext,
};
