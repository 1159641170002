import React, { ReactElement } from 'react';
import BasicApi from 'api/BasicApi';
import { Button } from 'semantic-ui-react';

type Props = {
    userId: string,
    taskId: string,
    reload: (taskId: string) => void,
};

/**
 * Allocates a review stage to the current user
 */
export function AllocateToMeButton({
    userId,
    taskId,
    reload,
}: Props): ReactElement | null {
    function allocate(): Promise<void> {
        return BasicApi.post(
                `/api/tasks/${taskId}/allocate`,
                {reviewerId: userId},
            )
            .then(() => reload(taskId));
    }

    return (
        <Button onClick={allocate} size="tiny">
            Allocate to current user
        </Button>
    );
}
