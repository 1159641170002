import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import PropTypes from 'prop-types';
import { Button, Loader, Modal, Tab } from 'semantic-ui-react';
import StagedRecordsList from 'components/customer/StagedRecordsList';
import StagedFilesList from 'components/customer/StagedFilesList';
import BasicApi from 'api/BasicApi';

class LinkDocumentsModal extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            selectedFiles: [],
            selectedRecords: [],
            files: [],
            records: [],
            loadingFiles: false,
            loadingRecords: false,
            _isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({_isMounted: true});
        this.getStagedFiles();
        this.getStagedRecords();
    }

    componentWillUnmount() {
        this.setState({_isMounted: false});
    }

    onClose() {
        this.setState({selectedFiles: [], selectedRecords: []});
        this.props.onClose();
    }

    getStagedFiles() {
        BasicApi.get(`/api/cases/${this.props.caseId}/staged-files`)
            .then(this.setFiles)
            .catch(BasicApi.logError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({loadingFiles: false});
                }
            });
    }

    setFiles(data) {
        if (this.state._isMounted) {
            this.setState({files: data});
        }
    }

    getStagedRecords() {
        this.setState({loadingRecords: true});
        BasicApi.get(`/api/cases/${this.props.caseId}/staged-records`)
            .then(this.setRecords)
            .catch(BasicApi.logError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({loadingRecords: false});
                }
            });
    }

    setRecords(data) {
        if (this.state._isMounted) {
            this.setState({records: data});
        }
    }

    linkFilesAndRecords() {
        if (!this.props.onLinkDocuments) {
            return
        }
        this.props.onLinkDocuments("files", this.state.selectedFiles);
        this.props.onLinkDocuments("records", this.state.selectedRecords);
        this.setState({selectedFiles: [], selectedRecords: []});

        this.onClose();
    }

    onFileSelectChange(newSelectedFiles) {
        this.setState({selectedFiles: newSelectedFiles})
    }

    onRecordSelectChange(newSelectedRecords) {
        this.setState({selectedRecords: newSelectedRecords})
    }

    render() {
        return (
            <Modal
                onClose={this.onClose}
                open={this.props.visible}
                centered={false}
            >
                <Modal.Header>
                    Link Customer Documents to Stage
                </Modal.Header>
                <Modal.Content>
                    <Tab
                        renderActiveOnly={false}
                        panes={[
                            {
                                menuItem: 'Artefacts',
                                pane: (
                                    <Tab.Pane key='files'>
                                        {this.state.loadingFiles && <Loader />}
                                        <StagedFilesList
                                            onFileSelect={this.onFileSelectChange}
                                            downloadUrl={`/api/reviews/${this.props.taskId}/files`}
                                            files={this.state.files}
                                        />
                                    </Tab.Pane>
                                ),
                            },
                            {
                                menuItem: 'Structured Data',
                                pane: (
                                    <Tab.Pane key='records'>
                                        {this.state.loadingRecords && <Loader /> }
                                        <StagedRecordsList
                                            records={this.state.records}
                                            onRecordSelect={this.onRecordSelectChange}
                                        />
                                    </Tab.Pane>
                                ),
                            },
                        ]}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Cancel"
                        onClick={this.onClose}
                    />
                    <Button
                        content="Link"
                        primary
                        disabled={!this.state.selectedRecords.length && !this.state.selectedFiles.length}
                        onClick={this.linkFilesAndRecords}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

}

LinkDocumentsModal.propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    caseId: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    onLinkDocuments: PropTypes.func.isRequired,
}

LinkDocumentsModal.defaultProps = {
    visible: false,
}

export default LinkDocumentsModal;
