import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Button, Form, Header, Icon, Message, Modal, Popup } from 'semantic-ui-react';
import BasicApi from 'api/BasicApi';
import { FieldText } from 'external/form/GenericFormFields';
import 'components/admin/EditUserPasswordButton.scss';
import PasswordGenerator from 'common/auth/PasswordGenerator';
import ApiError from 'api/ApiError';

type EditUserPasswordButtonProps = {
    user: {
        id: number,
        name: string,
        displayName: string,
    },
    setError: (error: string) => void,
    onSuccess: (success: string) => void,
    close: () => void,
}

const PASSWORD_GEN_LENGTH = 32;

const EditUserPasswordModal = ({user, onSuccess, close}
    : EditUserPasswordButtonProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordNotEqual, setPasswordNotEqual] = useState<boolean>(false);
    const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

    const setGeneratedPassword = async () => {
        let password = PasswordGenerator(PASSWORD_GEN_LENGTH);
        await navigator.clipboard.writeText(password);
        setNewPassword(password);
        setConfirmPassword(password);
    };

    const changePassword = (event: SyntheticEvent) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setPasswordNotEqual(true);
            return;
        }

        setIsLoading(true);
        BasicApi.put(`/api/admin/user/password`, {
                userId: user.id,
                newPassword: newPassword,
            })
            .then(() => {
                if (onSuccess) {
                    setIsLoading(false);
                    onSuccess('Password changed successfully');
                    close();
                }
            })
            .catch((error: ApiError) => {
                setErrorMessageList(error.messageList ?? []);
            })
            .finally(() => setIsLoading(false))
    };

    return (
        <>
            <Modal
                open
                size="small"
                closeOnEscape={true}
                centered={false}
            >
                <Header>
                    <Header.Content className="fluid">
                        <>
                            <Icon name="lock"/>
                            Change Password for {user.displayName}
                            <Popup
                                content={'Copied to Clipboard'}
                                on={'click'}
                                position={'top center'}
                                trigger={
                                    <Button floated="right" onClick={setGeneratedPassword}>
                                        <Icon name="sync alternate" />
                                        Generate Password
                                    </Button>
                                }
                            />
                        </>
                    </Header.Content>
                    <Header.Subheader>
                        Please send new password to user over an encrypted and trusted channel
                        <br/>And request user to manually change password upon login
                    </Header.Subheader>
                </Header>
                <Modal.Content>
                    <Form>
                        {passwordNotEqual &&
                            <Message negative>
                                <Message.Header>Passwords Do Not Match</Message.Header>
                                <p>The provided new password and confirmation password do not match</p>
                            </Message>
                        }
                        {errorMessageList.length !== 0 &&
                            <Message negative>
                                <Message.Header>Error Updating Password</Message.Header>
                                <ul>{errorMessageList.map(msg => {
                                    return <li>{msg}</li>
                                })}</ul>
                            </Message>
                        }
                        <label className="external-form-label">New Password</label>
                        <FieldText
                            name="password"
                            inputType="password"
                            value={newPassword}
                            onChange={(_event: ChangeEvent<HTMLInputElement>, value: string) => {
                                setNewPassword(value);
                            }}
                        />
                        <label className="external-form-label">Confirm New Password</label>
                        <FieldText
                            name="confirm password"
                            inputType="password"
                            value={confirmPassword}
                            onChange={(_event: ChangeEvent<HTMLInputElement>, value: string) => {
                                setConfirmPassword(value);
                            }}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close}>
                        <Icon name="remove" /> Cancel
                    </Button>
                    <Button
                        primary
                        onClick={changePassword}
                        loading={isLoading}
                    >
                        <Icon name="checkmark" /> Set New Password
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default EditUserPasswordModal;