import React, { useContext } from 'react';
import { UserContext } from 'UserContext';
import { Redirect, useLocation } from 'react-router-dom';
import { userHasAnyPermission } from 'common/auth/Authority';

interface Props {
    permissions: any,
    requireAll?: boolean
}

const RedirectToImproperAuthenticationPage: React.FC<{location: string}> = ({location}) => (
    <Redirect
        to={{
            pathname: '/unauthenticated',
            search: `?redirect=${location}`,
            state: {referrer: location},
        }}
    />
);

let RequirePermissions: React.FC<Props> = ({permissions, children, requireAll}) => {
    const userContext = useContext(UserContext);
    const location = useLocation();

    let hasPermission;
    if (requireAll) {
        hasPermission = userContext.hasPermissions(...permissions);
    } else {
        hasPermission = userContext.user?.permissions && userHasAnyPermission(permissions, userContext);
    }

    if (!hasPermission) {
        return <RedirectToImproperAuthenticationPage location={location.pathname} />;
    }

    return (
        <>
            {children}
        </>
    );
};

export {
    RequirePermissions as default,
};

