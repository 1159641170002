import BasicApi from 'api/BasicApi';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

async function createCustomerFile(customerId, fileUpload) {
    const data = prepareCustomerUpload(customerId, fileUpload);
    const fileDataUrl = `/api/customers/${customerId}/staged-files`;
    return BasicApi.postMultiPartForm(fileDataUrl, data);
}

async function createReviewStageFile(taskId, fileUpload) {
    const data = prepareCaseUpload(fileUpload);
    const fileDataUrl = `/api/reviews/${taskId}/files`;
    return BasicApi.postMultiPartForm(fileDataUrl, data);
}

async function importCustomersFromFile(fileUpload, tag) {
    const data = prepareFileUpload(fileUpload, tag);
    const fileDataUrl = `/api/customers/import`;
    return BasicApi.postMultiPartForm(fileDataUrl, data);
}

function prepareCustomerUpload(customerId, fileUpload) {
    let newFile = cloneDeep(omit(fileUpload, ['file']));
    newFile.customerId = customerId;
    return createFileRequest(newFile, fileUpload);
}

function prepareCaseUpload(fileUpload) {
    let newFile = cloneDeep(omit(fileUpload, ['file']));
    return createFileRequest(newFile, fileUpload);
}

function prepareFileUpload(fileUpload, tag) {
    let newFile = cloneDeep(omit(fileUpload, ['file']));
    if (tag) {
        newFile.fileTag = tag;
    }
    return createFileRequest(newFile, fileUpload);
}

function createFileRequest(newFile, fileUpload) {
    let filePart;
    if (fileUpload instanceof File) {
        filePart = fileUpload;
        newFile.fileName = fileUpload.name;
    }

    return BasicApi.createMultipartJsonAndFileRequest('file', newFile, 'data', filePart);
}

export {
    createCustomerFile,
    createReviewStageFile,
    importCustomersFromFile,
}